<div class="nav-item" [class.inactive]="inactive" [class.subnav-item]="isSubnavItem">
  <a class="nav-link" [class.fixed]="!isSubnavItem">
    <div class="icon" [class.subnav-icon]="isSubnavItem" [class.tagged-icon]="tag.length > 0">
      <i class="{{ icon }}"></i>
    </div>
    <div class="title">
      <label class="mat-subheading-1">
        <span>{{ navTitle }}</span>
        <span class="tag mat-caption try {{ tag }}" *ngIf="tag.length > 0">{{ tag }}</span>
      </label>
      <span class="mat-caption" *ngIf="!!description">{{ description }}</span>
    </div>
    <i class="ci-chevron-right-32" *ngIf="haveSubnavigation"></i>
  </a>

  <sidebar-subnav *ngIf="haveSubnavigation">
    <ng-content></ng-content>
  </sidebar-subnav>
</div>
