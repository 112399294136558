import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'core-sidebar-wrapper',
    templateUrl: './sidebar-wrapper.component.html',
    styleUrls: ['./sidebar-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

/**
 *  @class SidebarComponent
 *
 *  Component Sidebar component is used as side navigation of application
 *
 */
export class SidebarWrapperComponent {
  public opened = true;
  public openedSidenav = false;
}
