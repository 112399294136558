import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import packageInfo from '../../package.json';
import { first, interval, concat, filter } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

declare const gtag: (name: string, id: any, options: any) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public version = packageInfo.version;

  private subs = new SubSink();

  constructor(
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private appRef: ApplicationRef,
    private serviceWorkerUdate: SwUpdate,
  ) {}

  public ngOnInit(): void {
    this.setupAnalitics();
    this.setupChecksForVersionUpdates();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private setupChecksForVersionUpdates() {
    if (!this.serviceWorkerUdate.isEnabled) {
      console.log('Service workers are disabled or not supported by this browser');

      return;
    }

    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    this.subs.sink = concat(appIsStable$, interval(60 * 1000)).subscribe(async () => {
      try {
        if (environment.name === 'development') {
          console.log('Service Worker: Checking for new application version.');
        }
        await this.serviceWorkerUdate.checkForUpdate();
      } catch (err) {
        console.error('Service Worker: Failed to check for updates:', err);
      }
    });

    this.subs.sink = this.serviceWorkerUdate.versionUpdates
      .pipe(filter((evt) => evt.type === 'VERSION_READY'))
      .subscribe(() => {
        if (
          // eslint-disable-next-line no-alert
          confirm(
            'An update to Construct is available. We recommend you load it now for best application performance. Would you like to proceed?',
          )
        ) {
          document.location.reload();
        }
      });

    this.subs.sink = this.serviceWorkerUdate.unrecoverable.subscribe(() => {
      document.location.reload();
    });
  }

  private setupAnalitics() {
    if (environment.googleAnaliticsConfig) {
      gtag('config', environment.googleAnaliticsConfig.measurementId, {});
      this.angulartics.startTracking();
    }
  }
}
