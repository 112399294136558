import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanPipe } from './pipes/can.pipe';
import { RoleOnlyPipe } from './pipes/role-only.pipe';
import { RoleExceptPipe } from './pipes/role-except.pipe';

@NgModule({
  declarations: [CanPipe, RoleOnlyPipe, RoleExceptPipe],
  imports: [CommonModule],
  exports: [CanPipe, RoleOnlyPipe, RoleExceptPipe],
})
export class PermissionModule {}
