import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFormComponent } from './user-form/user-form.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { TbsInputModule } from '@ngx-terabase/ui/input';
import { TbsSelectModule } from '@ngx-terabase/ui/select';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { CoreTextareaModule } from '../core-textarea/core-textarea.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CoreFileUploadModule } from '../core-file-upload/core-file-upload.module';
import { PermissionModule } from 'src/app/permission/permission.module';
import { TbsButtonModule } from '@ngx-terabase/ui/button';

const CORE_MODULES = [CoreTextareaModule, CoreFileUploadModule];
const TERABASE_MODULES = [TbsInputModule, TbsSelectModule, TbsButtonModule];
const MATERIAL_MODULES = [MatFormFieldModule, MatSlideToggleModule, MatSelectModule, MatInputModule];

@NgModule({
  declarations: [UserFormComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    PermissionModule,
    CORE_MODULES,
    TERABASE_MODULES,
    MATERIAL_MODULES,
  ],
  exports: [UserFormComponent],
})
export class CoreUserFormModule {}
