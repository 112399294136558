import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface FileUploadInterface {
  base64: string;
  binary: any;
}

@Component({
    selector: 'core-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    standalone: false
})
/**
 * @external FormControl from '@angular/forms';
 * @export FileUploadComponent
 * @author stefan.kuburovic@htecgroup.com
 * @template <core-file-upload></core-file-upload>
 *
 * This component is component for file upload components
 * it have dependency of FormModules and MaterialModule that is already included in library
 *
 * @param id: boolean: false - defining id of file upload component
 *
 * @param custom: any | undefined - parameter where you can add your own style that will be applied to button of file-upload component
 *  e.g: {
 *      width: '600px'
 *  }
 *
 * @param size: 'medium' | 'large' | 'full' | 'x-small' - determines default sizes for button in file-upload component
 *  this sizes are determined by design so we have:
 *  - medium: 372px;
 *  - large: 500px;
 *  - full: 100%;
 *  - x-small: 80px; - by choosing x-small, label will change styles to mat-body-1, default is mat-body-2,
 *      for more about typography check -> https://material.angular.io/guide/typography
 *
 * @param title: string | undefined - adding title to fileupload button component
 * @param fileUploadTitle: string | undefined - adding title to fileupload component
 *
 * @param fileSize: number: 50000 - Setting max size of file(s) that can be uploaded.
 * @param noteClass: string: - Setting typography class to note
 * @param fileTypes: string[]: Setting array of types that can be uploaded.
 * @param dimensions: number[]: Setting dimensions that can be uploaded
 *  - number[0] - width
 *  - number[1] - height
 *
 * @param multiple: boolean | undefined - Setting if you can upload multiple files.
 *
 * @param errorMessage: string: 'This field is required' setting error message label for field
 *
 * @param control: any - Creating FormControl for input field, more about FormControl: https://angular.io/api/forms/FormControl
 *
 * @param emitFile = new EventEmitter<object>() = Event emitted when the file is uplaoded:
 *
 * ...emit({
 *               base64: reader.result as string, - emitting base64
 *               binary: fileList - emitting binary
 *           });
 */
export class FileUploadComponent {
  @Input() public id = '';
  @Input() public custom: any;
  @Input() public note?: string;
  @Input() public title?: string;
  @Input() public size = 'medium';
  @Input() public fileSize = 10000000;
  @Input() public noteClass?: string;
  @Input() public multiple?: boolean;
  @Input() public fileTypes?: string[];
  @Input() public dimensions?: number[];
  @Input() public fileUploadTitle?: string;
  @Input() public strictDimensions = false;
  @Input() public control?: any = new UntypedFormControl(['']);

  @Output() public emitFile = new EventEmitter<FileUploadInterface>();

  public fileName?: string;
  public errorMessage?: string;

  public triggerClick(): void {
    const element: HTMLElement = document.getElementById(this.id) as HTMLElement;
    element.click();
  }

  public validationAndUpload(event: any): void {
    const element = event.target as HTMLInputElement;
    const fileList: FileList | null = element.files;
    const reader = new FileReader();

    if (!this.multiple && fileList) {
      const file = fileList[0];
      if (file.size > this.fileSize) {
        this.errorMessage = 'Error: Image size iz too big';

        return;
      }

      if (!this.fileTypes?.includes(file.type) && this.fileTypes) {
        this.errorMessage = 'Error: Invalid image format';

        return;
      }
      if (file && file.type.includes('image')) {
        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            if (
              this.strictDimensions &&
              this.dimensions &&
              this.dimensions[0] !== img.naturalWidth &&
              this.dimensions[1] !== img.naturalHeight
            ) {
              this.errorMessage = 'Please check dimensions';
            } else if (
              !this.strictDimensions &&
              this.dimensions &&
              this.dimensions[0] > img.naturalWidth &&
              this.dimensions[1] > img.naturalHeight
            ) {
              this.errorMessage = 'Please check dimensions';
            } else {
              this.errorMessage = '';
              this.emitFileList(fileList);
            }
          };
        };
      } else {
        this.emitFileList(fileList);
      }
    } else if (this.multiple && fileList) {
      this.emitFileList(fileList);
    }
  }

  public emitFileList(fileList: FileList): void {
    if (fileList?.length === 1) {
      this.fileName = fileList[0].name;
      this.readFilesAndEmit(fileList[0]);
    } else {
      for (const file of Array.from(fileList)) {
        this.readFilesAndEmit(file);
      }
    }
  }

  public readFilesAndEmit(fileList: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(fileList);
    reader.onload = () => {
      this.emitFile.emit({
        base64: reader.result as string,
        binary: fileList,
      });
      (document.getElementById(this.id) as HTMLInputElement).value = '';
    };
  }
}
