import { Injectable } from '@angular/core';
import { LocalStorageKeyEnum } from '../../enums/local-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public getItem(key: LocalStorageKeyEnum): string | null {
    return localStorage.getItem(key);
  }

  public setItem(key: LocalStorageKeyEnum, value: string): void {
    localStorage.setItem(key, value);
  }

  public removeItem(key: LocalStorageKeyEnum) {
    localStorage.removeItem(key);
  }
}
