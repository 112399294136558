import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../common/local-storage.service';
import { LocalStorageKeyEnum } from '../../enums/local-storage-key.enum';

interface QueryParams {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public headers =
    this.localStorage.getItem(LocalStorageKeyEnum.TerabaseAuth) !== null
      ? new HttpHeaders().set(
          'Authorization',
          'Bearer ' + JSON.parse(this.localStorage.getItem(LocalStorageKeyEnum.TerabaseAuth) as string).access_token
        )
      : {};
  private END_POINT: string;

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.END_POINT = `${environment.apiUrl}/${environment.apiVersion}`;
  }

  /**
   *
   *  * the user here can pass the return type
   *      e.g : this.serviec.getRemove<_TYPE_>(....)
   *  * if the user does not provide an id -> getting all resources
   *  * this will work on get and delete request with query params filtering
   */
  public getRemove<returnType>(
    id: number | null,
    route: string,
    qp: QueryParams = {},
    method: 'get' | 'delete' = 'get',
    headersRequired: boolean | null,
    clearsky = true
  ): Observable<returnType> {
    // TODO: Important to remove this
    const cfqu = this.correctFormatForQueryUrl(qp);

    return this.http.request(
      method,
      clearsky ? `${this.END_POINT}/${route}${id ? '/' + id : ''}${cfqu}` : `${route}${id ? '/' + id : ''}${cfqu}`,
      { headers: headersRequired ? this.headers : {} }
    ) as Observable<returnType>;
  }

  /**
   * Method for patch/post on routes
   */
  public postPatch<returnType>(
    route: string,
    data: any,
    id: number | null,
    method: 'post' | 'put' | 'patch' = 'post',
    headersRequired: boolean | null,
    qp: QueryParams = {}
  ): Observable<returnType> {
    const cfqu = this.correctFormatForQueryUrl(qp);

    return this.http[method](`${this.END_POINT}/${route}${id ? '/' + id : ''}${cfqu}`, data, {
      headers: headersRequired ? this.headers : {},
    }) as Observable<returnType>;
  }

  /**
   * In the return we will attach the '?' if the user provides a query params
   * and if the user provides a null we do not need to map the array to
   * anything, simply return ''.
   * if there query parameters that has some keys an values
   * e.g
   * const z = {userId: 1, name: 'Terabase'} then
   * this method will return ["userId=1", "name=Terabase"]
   */
  private correctFormatForQueryUrl(qp: QueryParams): string {
    if (qp === null) {
      return '';
    }
    const qpAsStr = this.mapQueryParamsToUrl(qp);

    return qpAsStr.length === 0 ? '' : `?${qpAsStr.join('&')}`;
  }

  /**
   * e.g :
   * const z = {userId: 1, name: 'Terabase'} then
   * this method will return ["userId=1", "name=Terabase"]
   */
  private mapQueryParamsToUrl(qp: QueryParams): Array<string> {
    return Object.keys(qp).map((key: string) => {
      return `${key}=${qp[key]}`;
    });
  }
}
