import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TbsButtonModule } from '@ngx-terabase/ui/button';

const TERABASE_MODULES = [TbsButtonModule];

@NgModule({
  declarations: [FileUploadComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TERABASE_MODULES,
  ],
  exports: [FileUploadComponent],
})
export class CoreFileUploadModule {}
