import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFormComponent } from './user-form/user-form.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { TbsSelectModule } from '@ngx-terabase/ui/select';
import { CoreFileUploadModule } from '../core-file-upload/core-file-upload.module';
import { PermissionModule } from 'src/app/permission/permission.module';
import { TbsButtonModule } from '@ngx-terabase/ui/button';
import { TbsFormModule } from '@ngx-terabase/ui/form';
import { TbsInputModule } from '@ngx-terabase/ui/input';
import { TbsSwitchModule } from '@ngx-terabase/ui/switch';

const CORE_MODULES = [CoreFileUploadModule];
const TERABASE_MODULES = [TbsSelectModule, TbsButtonModule, TbsFormModule, TbsInputModule, TbsSwitchModule];

@NgModule({
  declarations: [UserFormComponent],
  imports: [CommonModule, NgxSpinnerModule, ReactiveFormsModule, PermissionModule, CORE_MODULES, TERABASE_MODULES],
  exports: [UserFormComponent],
})
export class CoreUserFormModule {}
