import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyInterface } from '../../interfaces/company.interface';
import { UserInterface } from '../../interfaces/user.interface';
import { CompanyService } from '../rest/company.service';
import { UserRole, UserService } from '../rest/user.service';

export enum BusinessRole {
  SuperAdmin = 'super-admin',
  CompanyAdminOwnCompanyContent = 'company-admin-own-company-content',
  CompanyAdminOtherCompanyUserContent = 'company-admin-other-company-user-content',
  CompanyUserOwnUserContent = 'company-user-own-user-content',
  CompanyUserOwnCompanyContent = 'company-user-own-company-content',
  CompanyUserOtherCompanyUserContent = 'company-user-other-company-user-content',
}

@Injectable({
  providedIn: 'root',
})
export class BusinessRolesService {
  constructor(private userService: UserService, private companyService: CompanyService) {}

  /**
   * Function that calculates user business role
   * based on combination of users role and company object
   *
   * @param user : User
   * @param compare : User Object to compare with the current logged in user
   * @returns string: User business role as defined in
   */
  public getBusinessRole(userToCompareWith?: UserInterface): Observable<BusinessRole> {
    return combineLatest([this.userService.currentUser, this.companyService.company]).pipe(
      map(([user, company]) => {
        let businessRole: BusinessRole = BusinessRole.SuperAdmin;
        switch (user.data.userRole) {
          case UserRole.CompanyAdmin: {
            const companyAdminBusinessRole = this.getCompanyAdminBusinessRole(user.data, company);
            if (companyAdminBusinessRole) {
              businessRole = companyAdminBusinessRole;
            }
            break;
          }

          case UserRole.CompanyUser: {
            const companyUserBusinessRole = this.getCompanyUserBusinessRole(user.data, company, userToCompareWith);
            if (companyUserBusinessRole) {
              businessRole = companyUserBusinessRole;
            }
            break;
          }
        }

        return businessRole;
      })
    );
  }

  public getUserContextRole(user: UserInterface): string {
    let role: string = user.userRole;

    if (user.userRole !== UserRole.SuperAdmin) {
      const companyType = user.company_profile.is_supplier ? 'supplier' : 'buyer';
      role = `${role}:${companyType}`;
    }

    return role;
  }

  private getCompanyAdminBusinessRole(user: UserInterface, company: CompanyInterface): BusinessRole | null {
    // Business role: Organisation Admin on own organisation content
    if ((company && this.isCompanyAdmin(user, company)) || !company) {
      return BusinessRole.CompanyAdminOwnCompanyContent;
    }
    //Business role: Admin on other user or organisation content
    if (company && !this.isCompanyAdmin(user, company)) {
      return BusinessRole.CompanyAdminOtherCompanyUserContent;
    }

    return null;
  }

  private getCompanyUserBusinessRole(
    user: UserInterface,
    company: CompanyInterface,
    userToCompareWith: UserInterface | undefined
  ): BusinessRole | null {
    //Business role: User on own organisation content
    if (company && this.isCompanyUser(user, company)) {
      return BusinessRole.CompanyUserOwnCompanyContent;
    }
    //Business role: User on other user or organisation content
    if (company && !this.isCompanyUser(user, company)) {
      return BusinessRole.CompanyUserOtherCompanyUserContent;
    }
    //Business role: User on own user content
    if (
      !company ||
      (!!userToCompareWith && !!company && this.isCompanyUser(user, company) && user._id === userToCompareWith._id)
    ) {
      return BusinessRole.CompanyUserOwnUserContent;
    }

    return null;
  }

  // TODO: check potential bug isCompanyUser is same as isCompanyAdmin method
  private isCompanyUser(user: UserInterface, company: CompanyInterface): boolean {
    return company._id === user.contact_profile.company;
  }

  private isCompanyAdmin(user: UserInterface, company: CompanyInterface): boolean {
    return company._id === user.contact_profile.company;
  }
}
