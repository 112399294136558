<tbs-dialog tbsSeparator="top-bottom">
  <tbs-dialog-header tbsHeading="My Profile"></tbs-dialog-header>
  <mat-dialog-content>
    <core-user-form
      [data]="user"
      [dialog]="true"
      [submitForm]="submit"
      (formChanged)="valueChanged($event)"
    ></core-user-form>
  </mat-dialog-content>

  <div mat-dialog-actions align="end" class="flex-gap-10">
    <button tbs-button tbsType="ghost" mat-dialog-close>Close</button>
    <button tbs-button (click)="submitDialog()" [disabled]="!formDirty">Save</button>
  </div>
</tbs-dialog>
