import { UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';
import { ErrorService } from 'src/app/@core/services/common/error.service';

export class UserFormValidator {
  public static userFormValidator(
    controlNames: string[],
    errorService: ErrorService
  ): (formGroup: UntypedFormGroup) => void {
    return (formGroup: UntypedFormGroup) => {
      errorService.responseError.subscribe((data: any) => {
        const error: any = data?.error;
        if (error?.error_code === 'validation_error') {
          _.each(controlNames, (name) => {
            const control = formGroup.controls[name];

            if (error.data.roles && name === 'roles') {
              control.setErrors({ invalidUserRole: true, message: error.data.roles[0] });
            }
            if (error.data.position && name === 'position') {
              control.setErrors({ invalidPosition: true, message: error.data.position[0] });
            }
            if (error.data.sales_region && name === 'sales_region') {
              control.setErrors({ invalidSalesRegion: true, message: error.data.sales_region[0] });
            }
          });
          errorService.clearErrors();
        }
      });
    };
  }
}
