import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/rest/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, switchMap } from 'rxjs/operators';

type PresignedUrlImage = { data: { url: string } };

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
  ) {}

  /**
   * Function for getting Image from S3
   *
   * @return Observable<any>
   */
  public getImage(imageId?: string): Observable<any> {
    return this.apiService.getRemove<any>(null, `resources/images/${imageId}/`, {}, 'get', true);
  }

  public getPresignedUrlImage(imageId: string) {
    return this.http
      .get<PresignedUrlImage>(`${environment.apiUrl}/${environment.apiVersion}/resources/images/${imageId}/url/`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .pipe(
        map((image: PresignedUrlImage) => {
          return image.data;
        }),
      );
  }

  public getBase64FromPresignedUrl(id: string) {
    return this.getPresignedUrlImage(id).pipe(switchMap((data) => this.imageUrlToBase64(data.url)));
  }

  public getPresignedUrlFromExistingOrtho(constructionProjectId: string, imageId: string) {
    return this.http
      .get<PresignedUrlImage>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${constructionProjectId}/drone_orthos/${imageId}/presigned_url/`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(
        map((image: PresignedUrlImage) => {
          return image.data;
        }),
      );
  }

  public getPresignedUrlFromNewOrtho(constructionProjectId: string, url: string) {
    let params = new HttpParams();
    params = params.append('url', url);

    return this.http
      .get<PresignedUrlImage>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${constructionProjectId}/drone_orthos/presigned_url/`,
        {
          params,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(
        map((image: PresignedUrlImage) => {
          return image.data;
        }),
      );
  }

  private imageUrlToBase64(url: string) {
    const mimeType = this.mimeType(url);

    return this.http
      .get(url, {
        observe: 'body',
        responseType: 'arraybuffer',
      })
      .pipe(
        map((arrayBuffer) => {
          return (
            mimeType +
            btoa(
              Array.from(new Uint8Array(arrayBuffer))
                .map((b) => String.fromCharCode(b))
                .join(''),
            )
          );
        }),
      );
  }

  private mimeType(url: string) {
    const urlSegments = url.split('?');
    const segments = urlSegments[0].split('.');
    const extension = segments[segments.length - 1];

    return `data:image/${extension.toLowerCase()};base64,`;
  }
}
