import { CellToggleComponent } from '@ag-grid/cell-templates/cell-toggle/cell-toggle.component';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ConstructionProjectFeaturesValuesEnum } from '@construction/construction.enums';
import { ConstructionProjectFeaturesType } from '@construction/models/construction-project.model';
import { ConstructionProjectStoreService } from '@construction/services/construction-project-store.service';
import { ColDef, GridApi, GridReadyEvent, IsRowMaster } from 'ag-grid-community';
import { FeatureNamePipePipe } from './feature-name-pipe.pipe';
import { PerspectiveSelectionComponent } from './perspective-selection/perspective-selection.component';
import { environment } from 'src/environments/environment';

export enum EnabledFeature {
  OwnerEnabled = 'owner_enabled',
  EpcEnabled = 'epc_enabled',
  SupplierEnabled = 'supplier_enabled',
}

export type PerspectiveChangeParam = {
  perspectiveChange: (param: ConstructionProjectFeaturesType, role: EnabledFeature) => void;
};

@Component({
  selector: 'construction-features-list',
  templateUrl: './construction-features-list.component.html',
  styleUrls: ['./construction-features-list.component.scss'],
  providers: [FeatureNamePipePipe],
})
export class ConstructionFeaturesListComponent implements OnInit {
  @Output() public enabledProjectFeaturesEmitter = new EventEmitter();
  public get projectFeautures(): ConstructionProjectFeaturesType[] {
    return this._projectFeautures;
  }
  @Input() public set projectFeautures(value: ConstructionProjectFeaturesType[]) {
    this._projectFeautures = value;
    this.sortedProjectFeatures = this.sortFeaturesById(this._projectFeautures);
  }
  public featuresEnum = ConstructionProjectFeaturesValuesEnum;
  public enabledFeatureEnum = EnabledFeature;
  public sortedProjectFeatures: ConstructionProjectFeaturesType[];

  public detailCellRenderer = PerspectiveSelectionComponent;
  public detailCellRendererParams: PerspectiveChangeParam;

  public gridApi: GridApi<ConstructionProjectFeaturesType>;

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 90,
    resizable: false,
    suppressMenu: true,
    suppressMovable: true,
  };

  public columnDefs: ColDef[] = [
    {
      field: '',
      valueFormatter: () => '',
      headerValueGetter: () => '',
      headerName: '',
      minWidth: 50,
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      field: 'name',
      headerName: 'Feature',
      minWidth: 200,
      sortable: false,
      valueGetter: (params) => {
        return this.featureNamePipe.transform(params.data.name);
      },
    },
    {
      field: 'owner_enabled',
      headerName: 'Owner',
      maxWidth: 100,
      sortable: false,
      cellRendererSelector: (params) => {
        return {
          component: CellToggleComponent,
          params: {
            isChecked: params.value,
            isDisabled:
              params.data.name === this.featuresEnum.TERAFAB ||
              this.isMapFeaturesEnabled(params.data.name) ||
              this.itHasWorkingHours(params.data),
            change: (checked: boolean) => {
              const feature = params.data;
              feature.owner_enabled = checked;
              this.featureToggled(feature, this.enabledFeatureEnum.OwnerEnabled);
            },
          },
        };
      },
    },
    {
      field: 'epc_enabled',
      headerName: 'EPC',
      maxWidth: 100,
      sortable: false,
      cellRendererSelector: (params) => {
        return {
          component: CellToggleComponent,
          params: {
            isChecked: params.value,
            isDisabled:
              params.data.name === this.featuresEnum.TERAFAB ||
              this.isMapFeaturesEnabled(params.data.name) ||
              this.itHasWorkingHours(params.data),
            change: (checked: boolean) => {
              const feature = params.data;
              feature.epc_enabled = checked;
              this.featureToggled(feature, this.enabledFeatureEnum.EpcEnabled);
            },
          },
        };
      },
    },
    {
      field: 'supplier_enabled',
      headerName: 'Supplier',
      maxWidth: 100,
      sortable: false,
      cellRendererSelector: (params) => {
        return {
          component: CellToggleComponent,
          params: {
            isChecked: params.value,
            isDisabled:
              params.data.name !== this.featuresEnum.LOGISTICS_INVENTORY || this.itHasWorkingHours(params.data),
            change: (checked: boolean) => {
              const feature = params.data;
              feature.supplier_enabled = checked;
              this.featureToggled(feature, this.enabledFeatureEnum.SupplierEnabled);
            },
          },
        };
      },
    },
  ];

  private featureNamePipe = inject(FeatureNamePipePipe);
  private constructionProjectStoreService = inject(ConstructionProjectStoreService);
  private _projectFeautures: ConstructionProjectFeaturesType[];

  private constructionProject = this.constructionProjectStoreService.getConstructionProject();

  private isFeaturesEnabled = environment.featuresList.is_features_enabled;

  public ngOnInit(): void {
    this.detailCellRendererParams = {
      perspectiveChange: this.perspectiveChange,
    };
  }

  public onGridReady(params: GridReadyEvent<ConstructionProjectFeaturesType>) {
    this.gridApi = params.api;
  }

  public isRowMaster: IsRowMaster = (params: ConstructionProjectFeaturesType) => {
    return params.name === this.featuresEnum.DIGITAL_TWIN;
  };

  public perspectiveChange = (feature: ConstructionProjectFeaturesType, role: EnabledFeature) => {
    feature[role] = !(!feature.aerial_data![role] && !feature.ground_data![role]);

    const rowToUpdate = this.gridApi.getDisplayedRowAtIndex(feature.id);

    if (rowToUpdate) {
      this.gridApi.redrawRows({ rowNodes: [rowToUpdate] });
    }

    this.constructionProjectStoreService.checkChangedFeatures(this.sortedProjectFeatures);
    this.enabledProjectFeaturesEmitter.emit(this.sortedProjectFeatures);
  };

  private featureToggled(feature: ConstructionProjectFeaturesType, featureRoleEnabled: EnabledFeature): void {
    if (feature.name === this.featuresEnum.DIGITAL_TWIN) {
      feature.aerial_data![featureRoleEnabled] = feature[featureRoleEnabled];
      feature.ground_data![featureRoleEnabled] = feature[featureRoleEnabled];

      const rowToUpdate = this.gridApi.getDisplayedRowAtIndex(feature.id);

      if (rowToUpdate) {
        this.gridApi.redrawRows();
      }
    }

    this.constructionProjectStoreService.checkChangedFeatures(this.sortedProjectFeatures);
    this.enabledProjectFeaturesEmitter.emit(this.sortedProjectFeatures);
  }

  private sortFeaturesById(features: ConstructionProjectFeaturesType[] = []) {
    return features.sort((a, b) => a.id - b.id);
  }

  private itHasWorkingHours(feature: ConstructionProjectFeaturesType): boolean {
    const hasWorkingHours = !!this.constructionProject.project_workhours.length;
    const featureToDisable =
      feature.name === ConstructionProjectFeaturesValuesEnum.EQUIPMENT_TRACKING ||
      feature.name === ConstructionProjectFeaturesValuesEnum.PRODUCTION_TRACKING;

    return featureToDisable && !hasWorkingHours;
  }

  private isMapFeaturesEnabled(feature: string) {
    if (this.isFeaturesEnabled) return false;
    const disabledFeatures = ['production_tracking', 'equipment_tracking'];

    return disabledFeatures.includes(feature);
  }
}
