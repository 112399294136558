import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'nav-items-group',
    templateUrl: './nav-items-group.component.html',
    styleUrls: ['./nav-items-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

/**
 *  @class NavItemsGroupComponent
 *
 *  Component Sidebar component is used as side navigation of application
 *
 */
export class NavItemsGroupComponent {}
