import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/services/guards/auth.guard';
import { FirstLoginGuard } from '@core/services/guards/first-login.guard';
import { MainLayoutComponent } from '@layout/components/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard, FirstLoginGuard],
    children: [
      {
        path: ':component/company',
        loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: ':component/company/:id',
        loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: 'marketplace',
        loadChildren: () => import('./@marketplace/marketplace.module').then((m) => m.MarketplaceModule),
      },
      {
        path: 'construction',
        loadChildren: () => import('./@construction/construction.module').then((m) => m.ConstructionModule),
      },
      {
        path: '',
        redirectTo: 'construction/projects',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    
    path: 'print',
    canActivate: [AuthGuard],
    loadChildren: () => import('./@print/print.module').then((m) => m.PrintModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
