import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { UserRole, UserService } from 'src/app/@core/services/rest/user.service';
import { ProjectService } from '../../../@core/services/common/project.service';
import { AuthenticationService } from '../../../@core/services/rest/authentication.service';

import { ConstructionProjectList } from '@construction/models/construction-project.model';
import { ConstructionProjectsService } from '@construction/services/construction-project.service';
import { UserInterface } from 'src/app/@core/interfaces/user.interface';
import { SubSink } from 'subsink';
import { UserProfileDialogComponent } from '../user-profile-dialog/user-profile-dialog.component';
import { NotificationType, TbsNotificationService } from '@ngx-terabase/ui/notification';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class SidebarComponent implements OnInit, OnDestroy {
  public userName = '';
  public profilePicture?: string;
  public pinnedProjects: ConstructionProjectList[] = [];
  public unpinnedProjects: ConstructionProjectList[] = [];
  public userRole = '';
  public userRoleEnum = UserRole;
  public userData: UserInterface;
  public pinnedIdsMap = new Map();
  public arePinsChanged = false;

  private projectsToPin: ConstructionProjectList[] = [];
  private projectsToUnPin: ConstructionProjectList[] = [];
  private subs = new SubSink();

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private projectService: ProjectService,
    private authService: AuthenticationService,
    private constructionProjectsService: ConstructionProjectsService,
    private elementRef: ElementRef,
    private notificationService: TbsNotificationService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.subs.sink = this.userService.currentUser.subscribe((user) => {
      this.userName = `${user.data.contact_profile?.first_name} ${user.data.contact_profile?.last_name}`;
      this.profilePicture = user.data.contact_profile?.profile_picture?._id;
      this.userRole = user.data.businessRole || user.data.userRole;
      this.userData = user.data;
    });

    this.refreshProjects();
  }

  public logout(): void {
    this.authService.logOut();
  }

  public onMouseEnter() {
    if (!this.arePinsChanged) return;

    const toPinIds = this.projectsToPin.map(({ _id }) => _id);
    const pinnedIds = [...this.pinnedProjects, ...this.projectsToPin].map(({ _id }) => _id);

    const toUnPinIds = this.projectsToUnPin.map(({ _id }) => _id);
    const unPinnedIds = [...this.unpinnedProjects, ...this.projectsToUnPin].map(({ _id }) => _id);

    const filterredPinned = [...this.pinnedProjects, ...this.projectsToPin].filter(
      ({ _id }, index) => !pinnedIds.includes(_id, index + 1),
    );
    this.pinnedProjects = filterredPinned
      .filter((project) => !toUnPinIds.includes(project._id))
      .sort(this.sortProjectsByName);

    const filterredUnpinned = [...this.unpinnedProjects, ...this.projectsToUnPin].filter(
      ({ _id }, index) => !unPinnedIds.includes(_id, index + 1),
    );
    this.unpinnedProjects = filterredUnpinned
      .filter((project) => !toPinIds.includes(project._id))
      .sort(this.sortProjectsByName);

    this.projectsToPin = [];
    this.projectsToUnPin = [];
  }

  public onPinProject(selectedProject: ConstructionProjectList, pinned: boolean): void {
    this.subs.sink = this.projectService.pinProject(selectedProject._id, pinned).subscribe({
      next: () => {
        this.arePinsChanged = true;
        const listOfProjects = this.elementRef.nativeElement.getElementsByClassName('project-star');
        for (const project of listOfProjects) {
          if (selectedProject._id === project.id) {
            if (pinned) {
              project.classList.remove('ci-star-24');
              project.classList.add('ci-star-24-f', 'selected');
              this.projectsToPin.push(selectedProject);
            } else {
              project.classList.remove('ci-star-24-f', 'selected');
              project.classList.add('ci-star-24');
              this.projectsToUnPin.push(selectedProject);
            }
          }
        }
      },
      error: () => {
        this.notificationService.snackBar({
          title: 'Error',
          message: 'There was an error while pinning project. Please try again.',
          type: NotificationType.Error,
          duration: 5000,
        });
      },
    });
  }

  public navigateWithReload(route: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([route]);
    });
  }

  public openUsersProfile(): void {
    this.dialog.open(UserProfileDialogComponent);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private refreshProjects() {
    this.subs.sink = forkJoin([
      this.constructionProjectsService.getConstructionProjects(1, 1000),
      this.projectService.getPinnedProjects(this.userService.user._id),
    ]).subscribe(([projects, pinned]) => {
      const projectsWithUrl = projects.data.map((project) => {
        return {
          ...project,
          url: this.projectService.createProjectUrl(project._id, project.status),
        };
      });

      this.pinnedIdsMap = pinned.data.reduce((map, pinnedProject) => {
        map.set(pinnedProject.construction_project_id, true);

        return map;
      }, new Map());

      const { pinnedProjects, unpinnedProjects } = projectsWithUrl.reduce(
        (acc, project) => {
          const foundInPinned = this.pinnedIdsMap.has(project._id);

          if (foundInPinned) {
            acc.pinnedProjects.push(project);
          } else {
            acc.unpinnedProjects.push(project);
          }

          return acc;
        },
        {
          pinnedProjects: [] as ConstructionProjectList[],
          unpinnedProjects: [] as ConstructionProjectList[],
        },
      );
      this.arePinsChanged = false;
      this.pinnedProjects = pinnedProjects;
      this.unpinnedProjects = unpinnedProjects;
    });
  }

  private sortProjectsByName(a: ConstructionProjectList, b: ConstructionProjectList) {
    if ((a.project.project_name as string) < (b.project.project_name as string)) {
      return -1;
    }
    if ((a.project.project_name as string) < (b.project.project_name as string)) {
      return 1;
    }

    return 0;
  }
}
