import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavItemComponent {
  @Input() public icon = '';
  @Input() public tag = '';
  @Input() public isSubnavItem = false;
  @Input() public description?: string = '';
  @Input() public inactive = false;
  @Input() public haveSubnavigation = false;
  @Input() public subnavigationOpened = false;
  @Input() public navTitle?: string;
}
