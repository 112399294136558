import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Angulartics2Module } from 'angulartics2';
import 'ag-grid-enterprise';
import 'moment-timezone';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreModule } from '@core/core.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { LayoutModule } from '@layout/layout.module';
import { TbsNotificationModule } from '@ngx-terabase/ui/notification';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { ErrorResponseDialogComponent } from '@shared/error-response-dialog/error-response-dialog.component';
import { TbsDateAdapter } from '@ngx-terabase/ui/date-picker';
import moment, { Moment } from 'moment-timezone';
import { AgGridIcons, DefaultMomentAdapter } from './injection-tokens';

export type AgGridIconsType = {
  sortAscending: string;
  sortDescending: string;
  sortUnSort: string;
  rowDrag: string;
};

export class MomentDateAdapter implements TbsDateAdapter<Moment> {
  public toModel(value: Moment | null): Date | null {
    return value ? value.toDate() : null;
  }
  public toExternal(value: Date | null): Moment | null {
    return moment(value);
  }
}

const icons: AgGridIconsType = {
  sortAscending: '<i class="ci-arrow-up-24 ag-grid-sort-icon"/>',
  sortDescending: '<i class="ci-arrow-down-24 ag-grid-sort-icon"/>',
  sortUnSort: '<i class="ci-arrow-up-down-24 ag-grid-sort-icon"/>',
  rowDrag: "<i class='ci-drag-24'></i>",
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    NgxSpinnerModule,
    TbsNotificationModule,
    Angulartics2Module.forRoot({ pageTracking: { clearIds: true, clearQueryParams: true, clearHash: true } }),
    CoreModule.forRoot(),
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: ['development', 'development-test', 'staging', 'production'].includes(environment.name),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ErrorResponseDialogComponent,
  ],
  providers: [
    { provide: DefaultMomentAdapter, useClass: MomentDateAdapter },
    { provide: AgGridIcons, useValue: icons },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
