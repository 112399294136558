import { Component } from '@angular/core';

@Component({
    selector: 'nav-items-group-container',
    templateUrl: './nav-items-group-container.component.html',
    styleUrls: ['./nav-items-group-container.component.scss'],
    standalone: false
})

/**
 *  @class SidebarComponent
 *
 *  Component Sidebar component is used as side navigation of application
 *
 */
export class NavItemsGroupContainerComponent {}
