<router-outlet></router-outlet>
<div *ngIf="version" class="version">
  {{ version }}
</div>
<ngx-spinner
  type="ball-spin-clockwise"
  size="medium"
  name="core-spinner"
  template="<img src='../assets/images/placeholders/spinner.svg' alt='terabase loading' />"
></ngx-spinner>
