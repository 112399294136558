import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorResponseDialogComponent } from '@shared/error-response-dialog/error-response-dialog.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public responseError: Observable<any>;
  private responseErrorSubject: BehaviorSubject<any>;

  constructor(private dialog: MatDialog) {
    this.responseErrorSubject = new BehaviorSubject<any>(null);
    this.responseError = this.responseErrorSubject.asObservable();
  }

  public handleError(error: HttpErrorResponse, displayError = false) {
    this.responseErrorSubject.next(error);

    console.error(error);

    if (displayError) {
      this.displayErrorMessage();
    }
  }

  public clearErrors() {
    if (this.responseErrorSubject?.value) {
      this.responseErrorSubject.next(null);
    }
  }

  public clearErrorsAndCloseDialog(): void {
    if (this.responseErrorSubject?.value) {
      this.responseErrorSubject.next(null);
    }
    if (this.dialog) {
      this.dialog.closeAll();
    }
  }

  public displayErrorMessage() {
    const dialogRef = this.dialog.open(ErrorResponseDialogComponent, { width: '600px' });

    dialogRef.afterClosed().subscribe(() => {
      this.clearErrorsAndCloseDialog();
    });
  }
}
