import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IToggleCell } from '@construction/modules/project-edit/projects.types';

import { TbsSwitchModule } from '@ngx-terabase/ui/switch';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'cell-toggle',
    templateUrl: './cell-toggle.component.html',
    styleUrls: ['./cell-toggle.component.scss'],
    imports: [TbsSwitchModule, FormsModule],standalone: true
})
export class CellToggleComponent implements ICellRendererAngularComp {
  public params: ICellRendererParams & IToggleCell;
  public isChecked = false;
  public isDisabled = false;

  public agInit(params: ICellRendererParams & IToggleCell): void {
    this.params = params;
    this.isChecked = this.params.isChecked;
    if (this.params.isDisabled) {
      this.isDisabled = this.params.isDisabled;
    }
  }

  public refresh(): boolean {
    return true;
  }

  public toggleCheck(value: boolean) {
    this.isChecked = value;
    this.params.change(value);
  }
}
