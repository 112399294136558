import _ from 'lodash';
import { LocalStorageKeyEnum } from 'src/app/@core/enums/local-storage-key.enum';

export const mapStringsToTypes = (data: any) => {
  _.each(data, (value, key) => {
    if (value === 'null') {
      data[key] = null;
    }
    if (value === 'undefined') {
      data[key] = undefined;
    }
    if (value === 'true') {
      data[key] = true;
    }
    if (value === 'false') {
      data[key] = false;
    }
    if (typeof value === 'object') {
      mapStringsToTypes(value);
    }
  });

  return data;
};

interface ImageObject {
  binary: File;
  base64: string;
}
export const imageHelper = {
  saveTemporaryImage: (key: LocalStorageKeyEnum, image: ImageObject) => {
    const tmpImageStore: any = {};
    tmpImageStore.base64 = image.base64;
    tmpImageStore.name = image.binary.name;
    tmpImageStore.type = image.binary.type;
    localStorage.setItem(key, JSON.stringify(tmpImageStore));
  },
  readTemporaryImage: (key: LocalStorageKeyEnum): ImageObject => {
    const tmpImageJson: string = localStorage.getItem(key) || '';
    const tmpImage = JSON.parse(tmpImageJson);
    const base64 = tmpImage.base64;
    const base64parts = base64.split(',');
    const fileContent = base64parts[1];
    const file = new File([fileContent], tmpImage.name, { type: tmpImage.type });

    return {
      binary: file,
      base64,
    };
  },
};
