import { Component } from '@angular/core';

@Component({
  selector: 'nav-items-container',
  templateUrl: './nav-items-container.component.html',
  styleUrls: ['./nav-items-container.component.scss'],
})

/**
 *  @class SidebarComponent
 *
 *  Component Sidebar component is used as side navigation of application
 *
 */
export class NavItemsContainerComponent {}
