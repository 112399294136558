import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Directive({
  selector: '[tbsThrottleClick]',
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  @Input() public throttleTime = 3000;
  @Output() public throttleClick = new EventEmitter();
  public clicks = new Subject();
  private subs = new SubSink();

  public ngOnInit() {
    this.subs.sink = this.clicks.pipe(throttleTime(this.throttleTime)).subscribe((e) => this.throttleClick.emit(e));
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
  }

  @HostListener('click', ['$event'])
  public clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
