<div
  *ngIf="imageIsBackground"
  class="placeholder"
  [class.bordered]="!borderless"
  [style.background]="
    this.newImage
      ? 'url(' + newImage + ') center center / ' + size + ' no-repeat'
      : image && 'url(' + image + ') center center / ' + size + ' no-repeat'
  "
  [style.height]="dimensions && dimensions.height"
  [style.width]="dimensions && dimensions.width"
  [style.background-color]="backgroundColor"
  [style]="custom"
></div>

<div
  *ngIf="!imageIsBackground"
  class="placeholder"
  [style.height]="dimensions && dimensions.height"
  [style.width]="dimensions && dimensions.width"
>
  <img
    *ngIf="image"
    [src]="newImage ? newImage : image"
    alt="Alt"
    loading="lazy"
    [style.height]="dimensions && dimensions.height"
    [style.width]="dimensions && dimensions.width"
  />
</div>
