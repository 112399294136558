import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RolesService } from '../services/roles.service';

@Pipe({
  name: 'roleExcept',
})
export class RoleExceptPipe implements PipeTransform {
  constructor(private rolesService: RolesService) {}

  public transform(roles: string[]): Observable<boolean> {
    return this.rolesService.hasRoles(roles).pipe(map((has) => !has));
  }
}
