import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';
import { Amplify, ResourcesConfig } from 'aws-amplify';

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey(environment.agGridLicenseKey);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

const { userPoolId, userPoolWebClientId, identityPoolId, oauth } = environment.awsConfig.auth;

const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId,
      userPoolClientId: userPoolWebClientId,
      identityPoolId,
      loginWith: { oauth },
    },
  },
};

if (environment.awsConfig.analytics) {
  const { appId, region } = environment.awsConfig.analytics.AWSPinpoint;
  amplifyConfig.Analytics = { Pinpoint: { appId, region } };
}

Amplify.configure(amplifyConfig);
