import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'core-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

/**
 * @external FormControl from '@angular/forms';
 * @export TextareaComponent
 * @author stefan.kuburovic@htecgroup.com
 * @template <core-textarea></tbs-textarea>
 *
 * This component is default component for textareas that we are using on platform
 * it have dependency of FormModules and MaterialModule that is already included in library
 *
 *
 * @param custom: any | undefined - parameter where you can add your own style that will be applied to input field
 *  e.g: {
 *      width: '600px'
 *  }
 *
 * @param size: 'medium' | 'large' | 'full' | 'x-small' - determines default sizes for our input inside platform
 *  this sizes are determined by design so we have:
 *  - medium: 372px;
 *  - large: 500px;
 *  - full: 100%;
 *  - x-small: 80px; - by choosing x-small, label will change styles to mat-body-1, default is mat-body-2,
 *      for more about typography check -> https://material.angular.io/guide/typography
 *
 * @param label: string | undefined - adding label title to input field,
 * if param is undefined label will not be shown.
 *
 * @param autosize: boolean: Whether auto-sizing is enabled or not, if not size will be determined by size param or custom.
 *
 * If autosize true, this should be required:
 *
 * @param minRows: number: 1 - Minimum amount of rows in the textarea.
 * @param maxRows: number: 1 - Maximum amount of rows in the textarea.
 *
 * @param required: boolean: false - Setting input field to be required
 * @param readonly: boolean: false - Setting input field to be readonly
 * @param placeholder: string | undefined - Adding placeholder to input field
 *
 * @param predefinedValue: string | undefined - setting default value to input field, in other words pre-populating it
 *  if predefinedValue have value, input field will have another class with-predefined which will apply predefined value style
 *  this is important because readonly and fields with value have different styles, like this we are overriding readonly
 *
 * @param errorMessage: string: 'This field is required' setting error message label for field
 *
 * @param formGroup: any - Adding textarea to defined formGroup,
 * not necessary if there is param control https://angular.io/api/forms/FormGroup
 *
 * @param control: any - Creating FormControl for input field, more about FormControl: https://angular.io/api/forms/FormControl
 *
 *
 */
export class TextareaComponent {
  @Input() public minRows = 1;
  @Input() public maxRows = 1;
  @Input() public custom?: any;
  @Input() public reference?: string;
  @Input() public size?: string;
  @Input() public name = 'name';
  @Input() public label?: string;
  @Input() public autosize = true;
  @Input() public required = false;
  @Input() public readonly = false;
  @Input() public placeholder = '';
  @Input() public errorMessage = 'Error message';
  @Input() public predefinedValue: string | undefined;
  @Input() public formGroup = new UntypedFormGroup({});
  @Input() public control: any = new UntypedFormControl(['']);
  @Input() public formControl = new UntypedFormControl('', Validators.required);
}
