import { BusinessRoleContextBuilder } from '../business-role-context.builder';
import { PermissionCollection } from '../permission.collection';

export const userFormProfileViewContextPermissions = new BusinessRoleContextBuilder()
  .superAdminContent([
    PermissionCollection.userFormUploadImage,
    PermissionCollection.userFormViewFirstLastName,
    PermissionCollection.userFormEditDescription,
    PermissionCollection.userFormEditVideoIntro,
    PermissionCollection.userFormEditSalesRegion,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormEditPhoneNumber,
    PermissionCollection.userFormViewRole,
    PermissionCollection.userFormEditRole,
    PermissionCollection.userFormSaveProfile,
  ])
  .adminOwnCompanyContent([
    PermissionCollection.userFormUploadImage,
    PermissionCollection.userFormViewFirstLastName,
    PermissionCollection.userFormEditDescription,
    PermissionCollection.userFormEditVideoIntro,
    PermissionCollection.userFormEditSalesRegion,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormEditPhoneNumber,
    PermissionCollection.userFormViewRole,
    PermissionCollection.userFormEditRole,
    PermissionCollection.userFormSaveProfile,
  ])
  .userOwnUserContent([
    PermissionCollection.userFormUploadImage,
    PermissionCollection.userFormViewFirstLastName,
    PermissionCollection.userFormEditDescription,
    PermissionCollection.userFormEditVideoIntro,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormEditPhoneNumber,
    PermissionCollection.userFormViewRole,
    PermissionCollection.userFormEditRole,
    PermissionCollection.userFormSaveProfile,
  ])
  .userOwnCompanyContent([
    PermissionCollection.userFormUploadImage,
    PermissionCollection.userFormViewFirstLastName,
    PermissionCollection.userFormEditDescription,
    PermissionCollection.userFormEditVideoIntro,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormEditPhoneNumber,
    PermissionCollection.userFormViewRole,
    PermissionCollection.userFormSaveProfile,
  ])
  .userOtherUserContent([
    PermissionCollection.userFormUploadImage,
    PermissionCollection.userFormViewFirstLastName,
    PermissionCollection.userFormEditDescription,
    PermissionCollection.userFormEditVideoIntro,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormEditPhoneNumber,
    PermissionCollection.userFormViewRole,
    PermissionCollection.userFormSaveProfile,
  ])
  .adminOtherUserContent([
    PermissionCollection.userFormUploadImage,
    PermissionCollection.userFormViewFirstLastName,
    PermissionCollection.userFormEditDescription,
    PermissionCollection.userFormEditVideoIntro,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormEditPhoneNumber,
    PermissionCollection.userFormViewRole,
    PermissionCollection.userFormSaveProfile,
  ])
  .build();

export const userFormContactsViewContextPermissions = new BusinessRoleContextBuilder()
  .superAdminContent([
    PermissionCollection.userFormUploadImage,
    PermissionCollection.userFormStartChat,
    PermissionCollection.userFormEditDescription,
    PermissionCollection.userFormEditVideoIntro,
    PermissionCollection.userFormEditSalesRegion,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormEditPhoneNumber,
    PermissionCollection.userFormViewRole,
    PermissionCollection.userFormEditRole,
    PermissionCollection.userFormSaveProfile,
    PermissionCollection.userFormDisableUser,
  ])
  .adminOwnCompanyContent([
    PermissionCollection.userFormUploadImage,
    PermissionCollection.userFormStartChat,
    PermissionCollection.userFormEditDescription,
    PermissionCollection.userFormEditVideoIntro,
    PermissionCollection.userFormEditSalesRegion,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormEditPhoneNumber,
    PermissionCollection.userFormViewRole,
    PermissionCollection.userFormEditRole,
    PermissionCollection.userFormSaveProfile,
    PermissionCollection.userFormDisableUser,
  ])
  .userOwnUserContent([
    PermissionCollection.userFormUploadImage,
    PermissionCollection.userFormStartChat,
    PermissionCollection.userFormEditDescription,
    PermissionCollection.userFormEditVideoIntro,
    PermissionCollection.userFormEditSalesRegion,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormEditPhoneNumber,
    PermissionCollection.userFormViewRole,
    PermissionCollection.userFormEditRole,
    PermissionCollection.userFormSaveProfile,
  ])
  .userOwnCompanyContent([
    PermissionCollection.userFormViewFirstLastName,
    PermissionCollection.userFormStartChat,
    PermissionCollection.userFormViewKeyAccountManager,
    PermissionCollection.userFormViewRole,
  ])
  .userOtherUserContent([
    PermissionCollection.userFormViewFirstLastName,
    PermissionCollection.userFormStartChat,
    PermissionCollection.userFormViewKeyAccountManager,
  ])
  .adminOtherUserContent([
    PermissionCollection.userFormViewFirstLastName,
    PermissionCollection.userFormStartChat,
    PermissionCollection.userFormMakeMyKeyAccountManager,
  ])
  .build();
