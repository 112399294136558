<form tbs-form tbsLayout="vertical" [formGroup]="userForm" *ngIf="(currentUser$ | async)?.data as user">
  <div class="user-basics">
    <div class="user-profile">
      <div class="user-profile-image">
        <img *ngIf="profilePicture" [src]="profilePicture" alt="profile image" />
      </div>
      <core-file-upload
        *ngIf="[permission.userFormUploadImage] | can | async"
        [fileSize]="50000"
        [id]="data?._id"
        [dimensions]="[200, 200]"
        [title]="'Upload Image'"
        [noteClass]="'tb-caption'"
        (emitFile)="uploadProfilePicture($event)"
        [fileUploadTitle]="'Company Logo'"
        [control]="userForm?.get('profile_picture')"
        [fileTypes]="['image/png', 'image/jpg', 'image/jpeg']"
        [custom]="{ padding: 0, 'justify-content': 'flex-start', 'margin-top': '8px' }"
        [note]="'* Picture should be 200 x 200 px, up to 50kb and in .jpg or .png format.'"
      >
      </core-file-upload>

      <button
        (click)="deleteUploadedImage()"
        class="delete-uploaded-image"
        *ngIf="([permission.userFormUploadImage] | can | async) && profilePicture !== undefined && deleteProfileImage"
        tbs-button
        tbsType="text"
        tbsIconPosition="left"
      >
        <i class="ci-trash-24"></i>Delete Image
      </button>
    </div>
    <div>
      <div class="form-control">
        <tbs-form-item>
          <tbs-form-label>First Name</tbs-form-label>
          <tbs-form-control [tbsErrorTip]="firstNameErrorTpl">
            <input
              tbs-input
              placeholder="Your First Name"
              type="text"
              formControlName="first_name"
              [readonly]="([permission.userFormViewFirstLastName] | can | async) === false"
            />
            <ng-template #firstNameErrorTpl let-control>
              <ng-container *ngIf="control.errors?.['required']">This field is required.</ng-container>
            </ng-template>
          </tbs-form-control>
        </tbs-form-item>
      </div>

      <div class="form-control">
        <tbs-form-item>
          <tbs-form-label>Last Name</tbs-form-label>
          <tbs-form-control [tbsErrorTip]="lastNameErrorTpl">
            <input
              tbs-input
              placeholder="Your Last Name"
              type="text"
              formControlName="last_name"
              [readonly]="([permission.userFormViewFirstLastName] | can | async) === false"
            />
            <ng-template #lastNameErrorTpl let-control>
              <ng-container *ngIf="control.errors?.['required']">This field is required.</ng-container>
            </ng-template>
          </tbs-form-control>
        </tbs-form-item>
      </div>

      <div class="form-control">
        <tbs-form-item>
          <tbs-form-label>Email</tbs-form-label>
          <tbs-form-control [tbsErrorTip]="emailErrorTpl">
            <input tbs-input placeholder="Your Email" type="text" formControlName="email" />
            <ng-template #emailErrorTpl let-control>
              <ng-container *ngIf="control.errors?.['required']">This field is required.</ng-container>
            </ng-template>
          </tbs-form-control>
        </tbs-form-item>
      </div>

      <div class="form-control">
        <tbs-form-item>
          <tbs-form-label>Title</tbs-form-label>
          <tbs-form-control>
            <input
              tbs-input
              placeholder="Please enter title"
              type="text"
              formControlName="position"
              [readonly]="([permission.userFormViewRole] | can | async) === false"
            />
          </tbs-form-control>
        </tbs-form-item>
      </div>

      <div class="start-chat-button" *ngIf="([permission.userFormStartChat] | can | async) && !dialog">
        <button tbs-button tbsIconPosition="left"><i class="ci-speech-bubble-24"></i>Start Chat</button>
      </div>
    </div>
  </div>

  <div class="form-control description">
    <tbs-form-item>
      <tbs-form-label>Description</tbs-form-label>
      <tbs-form-control>
        <textarea
          tbs-input
          rows="3"
          placeholder="Add a few words about yourself"
          formControlName="description"
          [readonly]="([permission.userFormEditDescription] | can | async) === false"
        ></textarea>
      </tbs-form-control>
    </tbs-form-item>
  </div>

  <div class="form-control">
    <tbs-form-item>
      <tbs-form-label>Video Introduction</tbs-form-label>
      <tbs-form-control>
        <input
          tbs-input
          placeholder="Copy URL here"
          type="text"
          formControlName="video_introduction"
          [readonly]="([permission.userFormEditVideoIntro] | can | async) === false"
        />
      </tbs-form-control>
    </tbs-form-item>
  </div>

  <div class="form-control" *ngIf="router.url.indexOf('profile') === -1">
    <tbs-form-item>
      <tbs-form-label>Geographical focus</tbs-form-label>
      <tbs-form-control>
        <input
          tbs-input
          placeholder="Please enter geographical focus"
          type="text"
          formControlName="sales_region"
          [readonly]="([permission.userFormEditSalesRegion] | can | async) === false"
        />
      </tbs-form-control>
    </tbs-form-item>
  </div>

  <div
    *ngIf="([permission.userFormViewKeyAccountManager] | can | async) && kamForCompanies.length > 0"
    class="form-control"
  >
    <tbs-form-item>
      <tbs-form-label>Key Account Manager</tbs-form-label>
      <tbs-form-control>
        <input tbs-input formControlName="key_account_manager" placeholder="Enter for what company" readonly="true" />
      </tbs-form-control>
    </tbs-form-item>
  </div>
  <div class="form-control" *ngIf="([permission.userFormMakeMyKeyAccountManager] | can | async) && !dialog">
    <tbs-switch [(ngModel)]="kamForCompaniesChecked" (ngModelChange)="keyAccountManagerChange($event)"
      >Make it my Key Account Manager:</tbs-switch
    >
  </div>

  <div class="form-control">
    <tbs-form-item>
      <tbs-form-label>Phone number</tbs-form-label>
      <tbs-form-control>
        <input
          tbs-input
          formControlName="phone_number"
          placeholder="Enter phone number"
          [readonly]="([permission.userFormEditPhoneNumber] | can | async) === false"
        />
      </tbs-form-control>
    </tbs-form-item>
  </div>

  <div *ngIf="[permission.userFormViewRole] | can | async" class="form-control">
    <tbs-form-item>
      <tbs-form-label>Role</tbs-form-label>
      <tbs-form-control>
        <tbs-select
          formControlName="roles"
          [tbsDisabled]="([permission.userFormEditRole] | can | async) === false || user._id === data._id"
        >
          <tbs-option *ngFor="let role of roles" [tbsValue]="role.value" [tbsLabel]="role.text"></tbs-option>
        </tbs-select>
      </tbs-form-control>
    </tbs-form-item>
  </div>

  <div class="form-actions" *ngIf="([permission.userFormSaveProfile] | can | async) && !dialog">
    <button
      tbs-button
      tbsIconPosition="left"
      [disabled]="(userForm.pristine && user.first_login_finished) || submitted"
      (click)="submit()"
    >
      <i class="ci-check-24"></i>{{ !submitted ? 'Save Profile' : 'Profile Saved' }}
    </button>

    <button
      *ngIf="([permission.userFormDisableUser] | can | async) && user._id !== data._id"
      tbs-button
      [tbsType]="data.disabled ? 'solid' : 'text'"
      tbsIconPosition="left"
      (click)="changeEnablement()"
    >
      <i class="ci-x-24"></i>{{ data.disabled ? 'Enable User' : 'Disable User' }}
    </button>
  </div>
</form>

<ngx-spinner
  [name]="data._id"
  type="ball-spin-clockwise"
  size="medium"
  [bdColor]="spinnerFullscreen ? 'rgba(51, 51, 51, 0.8)' : 'rgba(244, 246, 249, 0.8)'"
  [fullScreen]="spinnerFullscreen"
></ngx-spinner>
