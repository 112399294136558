import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../common/error.service';
import { AuthenticationService } from '../rest/authentication.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService, private authService: AuthenticationService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Filter out accuweather requests for error handling
    if (request.url.startsWith('https://dataservice.accuweather.com')) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
          case 403:
          case 404:
          case 422:
            this.errorService.handleError(error);
            break;
          case 401:
            this.authService.logOut();
            break;
          default:
            this.errorService.handleError(error, true);
            break;
        }

        return throwError(error);
      })
    );
  }
}
