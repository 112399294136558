/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { ConstructionProjectFeaturesValuesEnum } from '@construction/construction.enums';
import {
  ConstructionProjectLoadModel,
  ConstructionProjectFeaturesType,
  QCType,
  QCScope,
  Sensor,
  ProjectWorkhours,
  ProjectMainRoleType,
} from '@construction/models/construction-project.model';
import { EquipmentSpecsType } from '@construction/modules/project-edit/modules/construction-equipment-specs/construction-equipment-specs.types';
import {
  ProcoreIntegration,
  ProcoreProjectPeople,
} from '@construction/modules/project-edit/modules/construction-integrations/construction-integration.types';
import { LockConstructionProjectStatesEnum } from '@construction/modules/project-edit/modules/construction-schedule/construction-schedule.enums';
import { PRIMARY_DATA_SOURCE } from '@construction/modules/project-edit/projects.enum';
import { PerspectiveSelection } from '@construction/modules/project-edit/projects.types';
import { EnabledFeature } from '@construction/shared/construction-features-list/construction-features-list/construction-features-list.component';
import { UserService } from '@core/services/rest/user.service';
import moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConstructionProjectStoreService {
  private constructionProjectSubject$: BehaviorSubject<ConstructionProjectLoadModel> = new BehaviorSubject(
    {} as ConstructionProjectLoadModel,
  );
  public constructionProject$ = this.constructionProjectSubject$.asObservable();

  public elementsWithWeightsSubject$ = new BehaviorSubject<string[]>([]);
  public visibleElementsSubject$ = new BehaviorSubject<string[]>([]);
  public lastEventStatusSubject$ = new BehaviorSubject<string | undefined>(undefined);

  private projectFeaturesSubject$ = new BehaviorSubject<ConstructionProjectFeaturesType[]>([]);
  public projectFeatures$ = this.projectFeaturesSubject$.asObservable();

  private qcTypesSubject$ = new BehaviorSubject<QCType[]>([]);
  public qcTypes$ = this.qcTypesSubject$.asObservable();

  private qcScopesSubject$ = new BehaviorSubject<QCScope[]>([]);
  public qcScopes$ = this.qcScopesSubject$.asObservable();

  private qcConfigPublishedSubject$ = new BehaviorSubject<boolean>(false);
  public qcConfigPublished$ = this.qcConfigPublishedSubject$.asObservable();

  private featurePermissionSubject$ = new BehaviorSubject({
    logistics_inventory: false,
    digital_twin: false,
    progress_tracking: false,
    equipment_tracking: false,
    omega: false,
    advanced_detection: false,
    qc_issues: false,
    approvals: false,
    mv_circuit: false,
    terafab: false,
  });
  public featurePermission$ = this.featurePermissionSubject$.asObservable();
  public lastDroneImage: string | Date | null;

  constructor(private userService: UserService) {}

  public updateConstructionProjectStore(constructionProject: ConstructionProjectLoadModel): void {
    this.constructionProjectSubject$.next(constructionProject);
    this.setConstructionProjectWeights(constructionProject);
    this.lastEventStatusSubject$.next(constructionProject.last_event_status?.status);
    this.checkChangedFeatures(constructionProject.feature_flags);
    this.qcTypesSubject$.next(constructionProject.qc_config?.qc_types || []);
    this.qcScopesSubject$.next(constructionProject.qc_config?.qc_scopes || []);
    this.qcConfigPublishedSubject$.next(!!constructionProject.qc_config?.published_at);
    this.setPermissionsForFeatures();
  }

  public updateEquipmentSpecs(equipmentSpecs: EquipmentSpecsType) {
    const constructionProject: ConstructionProjectLoadModel = {
      ...this.getConstructionProject(),
      equipment_specs: equipmentSpecs,
    };
    this.constructionProjectSubject$.next(constructionProject);
  }

  public updateConstructionProjectData(constructionProject: ConstructionProjectLoadModel) {
    const updatedProject = {
      ...this.getConstructionProject(),
      ...constructionProject,
    };
    this.constructionProjectSubject$.next(updatedProject);
  }

  public hasProjectRole(
    projectRole:
      | 'EPCQualityManagerRemediate'
      | 'EPCQualityManagerCreateRemediate'
      | 'OwnerConstructionManager'
      | 'OwnerCompanyUser'
      | 'EPCCompanyUser'
      | 'SupplierCompanyUser',
  ): boolean {
    const constructionProject = this.constructionProjectSubject$.getValue();

    const contactProfileId = this.userService.user.contact_profile._id;
    const companyProfileId = this.userService.user.company_profile?._id;

    switch (projectRole) {
      case 'EPCQualityManagerRemediate':
        return constructionProject.epc_quality_managers?.some((user) => user._id === contactProfileId) || false;
      case 'EPCQualityManagerCreateRemediate':
        return constructionProject.epc_cr_quality_managers?.some((user) => user._id === contactProfileId) || false;
      case 'OwnerConstructionManager':
        return constructionProject.owner_construction_managers?.some((user) => user._id === contactProfileId) || false;
      case 'OwnerCompanyUser':
        return (
          constructionProject.owner_construction_managers?.some((user) => companyProfileId === user.company) || false
        );
      case 'EPCCompanyUser':
        return this.userService.user.company_profile ? companyProfileId === constructionProject.epc_company : false;
      case 'SupplierCompanyUser':
        return this.userService.user.company_profile
          ? companyProfileId === constructionProject.supplier_company
          : false;
      default:
        return false;
    }
  }

  public wbsElementHasWeight(element: string): boolean {
    const elementsWithWeight = this.elementsWithWeightsSubject$.getValue();

    return elementsWithWeight.includes(element);
  }

  public visibleWBSElements(element: string): boolean {
    const visibleElements = this.visibleElementsSubject$.getValue();

    return visibleElements.includes(element);
  }

  public checkChangedFeatures(features: ConstructionProjectFeaturesType[]): void {
    this.projectFeaturesSubject$.next(features);
  }

  public getId(): string {
    return this.constructionProjectSubject$.getValue()._id;
  }

  public getConstructionProject(): ConstructionProjectLoadModel {
    return this.constructionProjectSubject$.getValue();
  }

  public updateQCTypes(qcTypes: QCType[]): void {
    this.qcTypesSubject$.next(qcTypes);
  }

  public getQcTypes(): QCType[] {
    return this.qcTypesSubject$.getValue();
  }

  public updateQCScopes(qcScopes: QCScope[]): void {
    this.qcScopesSubject$.next(qcScopes);
  }

  public getQcScopes(): QCScope[] {
    return this.qcScopesSubject$.getValue();
  }

  public updateQCConfigPublished(published: boolean): void {
    this.qcConfigPublishedSubject$.next(published);
  }

  public getQcConfigPublished(): boolean {
    return this.qcConfigPublishedSubject$.getValue();
  }

  public updateLastEventStatus(status: LockConstructionProjectStatesEnum) {
    this.lastEventStatusSubject$.next(status);
  }

  public setProcoreIntegration(integration: ProcoreIntegration) {
    const constructionProject: ConstructionProjectLoadModel = {
      ...this.getConstructionProject(),
      procore_integration: { procore_integration: integration },
    };
    this.constructionProjectSubject$.next(constructionProject);
  }

  public getActiveProcoreUsers(): ProcoreProjectPeople[] {
    const constructionProject = this.constructionProjectSubject$.getValue();
    if (!constructionProject.procore_integration) {
      return [];
    }

    return constructionProject.procore_integration.procore_integration.project_people.filter(
      (user) => user.user_id && user.contact.is_active,
    );
  }

  public getPreferedDataSourcePerspective(): PerspectiveSelection {
    const constructionProject = this.constructionProjectSubject$.getValue();

    if (this.hasProjectRole('EPCQualityManagerRemediate') || this.hasProjectRole('EPCQualityManagerCreateRemediate')) {
      return this.getPerspectiveByRole(constructionProject.feature_flags, EnabledFeature.EpcEnabled);
    } else if (this.hasProjectRole('OwnerConstructionManager')) {
      return this.getPerspectiveByRole(constructionProject.feature_flags);
    } else if (this.userService.isSuperAdmin()) {
      return this.getPerspectiveByRole(constructionProject.feature_flags);
    } else {
      return this.getPerspectiveByRole(constructionProject.feature_flags);
    }
  }

  public getProjectUsers() {
    const constructionProject = this.constructionProjectSubject$.getValue();

    return [
      ...(constructionProject.owner_construction_managers as ProjectMainRoleType[]),
      ...(constructionProject.epc_cr_quality_managers as ProjectMainRoleType[]),
      ...(constructionProject.epc_quality_managers as ProjectMainRoleType[]),
    ];
  }

  public setConstructionProjectSensors(sensors: Sensor[]): void {
    const constructionProject = this.constructionProjectSubject$.getValue();
    this.constructionProjectSubject$.next({ ...constructionProject, sensors });
  }

  public getProjectWorkHoursForDay(target: Moment): ProjectWorkhours | null {
    const constructionProject = this.constructionProjectSubject$.getValue();

    const sortedItems = constructionProject.project_workhours.sort((a, b) => moment(a.date).diff(moment(b.date)));
    let result: ProjectWorkhours | null = null;

    for (let i = 0; i < sortedItems.length; i++) {
      const currentDate = moment(sortedItems[i].date).tz(constructionProject.timezone, true);
      const nextDate =
        i < sortedItems.length - 1 ? moment(sortedItems[i + 1].date).tz(constructionProject.timezone, true) : null;

      if (target.isSameOrAfter(currentDate, 'date') && (!nextDate || target.isBefore(nextDate, 'date'))) {
        result = sortedItems[i];
        break;
      }
    }

    return result;
  }

  private setPermissionsForFeatures(): void {
    const isOwner = this.hasProjectRole('OwnerConstructionManager');
    const isEpc =
      this.hasProjectRole('EPCQualityManagerRemediate') || this.hasProjectRole('EPCQualityManagerCreateRemediate');
    const isUserFromSupplierCompany = this.hasProjectRole('SupplierCompanyUser');
    const features = this.projectFeaturesSubject$.getValue();

    const permissions: any = {};
    features.forEach((feature) => {
      let isEnabled = false;
      if (isOwner && feature.owner_enabled) {
        isEnabled = true;
      } else if (isEpc && feature.epc_enabled) {
        isEnabled = true;
      } else if (isUserFromSupplierCompany && feature.supplier_enabled) {
        isEnabled = true;
      } else if (this.userService.isSuperAdmin() && feature.owner_enabled) {
        isEnabled = true;
      }

      permissions[feature.name] = isEnabled;
    });
    this.featurePermissionSubject$.next(permissions);
  }

  private setConstructionProjectWeights(project: ConstructionProjectLoadModel) {
    const elementsWithWeight: string[] = [];
    const visibleElements: string[] = [];
    const weights = project.weights;

    if (weights) {
      Object.entries(weights).forEach(([key, value]) => {
        if (value !== null) {
          visibleElements.push(key);
          if (value > 0) {
            elementsWithWeight.push(key);
          }
        }
      });
    }
    this.elementsWithWeightsSubject$.next(elementsWithWeight);
    this.visibleElementsSubject$.next(visibleElements);
  }

  private getPerspectiveByRole(
    features: ConstructionProjectFeaturesType[],
    role = EnabledFeature.OwnerEnabled,
  ): PerspectiveSelection {
    //Find feature that determines perspective output
    const featurePerspective = features.find(
      (feature) => feature.name === ConstructionProjectFeaturesValuesEnum.DIGITAL_TWIN,
    );

    //Set default values
    const perspectiveSelected: PerspectiveSelection = { dualData: false, primarySource: PRIMARY_DATA_SOURCE.owner };

    if (!featurePerspective) return perspectiveSelected;

    //If both Aerial and Ground are turned on, set dual data
    perspectiveSelected.dualData = featurePerspective.aerial_data![role] && featurePerspective.ground_data![role];

    //If it is dual data, set default perspective for selected role
    if (perspectiveSelected.dualData) {
      perspectiveSelected.primarySource =
        role === EnabledFeature.OwnerEnabled ? PRIMARY_DATA_SOURCE.owner : PRIMARY_DATA_SOURCE.epc;
    } else if (featurePerspective.aerial_data![role]) {
      //If it is only Aerial selected
      perspectiveSelected.primarySource = PRIMARY_DATA_SOURCE.owner;
    } else {
      //It it is only Ground Selected
      perspectiveSelected.primarySource = PRIMARY_DATA_SOURCE.epc;
    }

    return perspectiveSelected;
  }
}
