import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/@core/services/rest/api.service';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LastFinishedSegmentEnum } from '@construction/modules/project-edit/projects.enum';
import { environment } from 'src/environments/environment';
import { PaginatedResponse, ProjectModel } from '@core/models/project.model';
import { ProjectStatusEnum } from '@construction/construction.enums';

export type ConstructionProjectSegment = {
  construction_start_date?: string | Date;
  construction_end_date?: string | Date;
  epc_company?: string;
  last_finished_segment?: string;
  submitted_at?: string;
  _id?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public constructionProjects$: Observable<any>;
  public constructionProjectsSubject$: BehaviorSubject<any>;

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
  ) {
    this.constructionProjectsSubject$ = new BehaviorSubject<any>({} as any);
    this.constructionProjects$ = this.constructionProjectsSubject$.asObservable();
  }

  /**
   * Creating or updating project
   * @param: data: any
   * @param: id: string
   * @return Observable<any>
   */
  public createOrUpdateProject(data: any, id: string | null = null): Observable<any> {
    if (id) {
      return this.apiService.postPatch(`projects/${id}/`, data, null, 'patch', true);
    } else {
      return this.apiService.postPatch('projects/', data, null, 'post', true);
    }
  }

  /**
   * Creating or updating project
   * @param: data: any
   * @param: id: string
   * @return Observable<any>
   */
  public updateProjectThumbnail(
    id: string | undefined,
    thumbnailId: string | null = null,
    data: FormData,
  ): Observable<any> {
    return this.apiService.postPatch(`projects/${id}/thumbnails/${thumbnailId}/`, data, null, 'put', true);
  }

  /**
   * Function for getting array of projects for specific user
   * @param: id: string
   * @return Observable<any>
   */
  public getProjects(
    id = '',
    company = '',
    page?: number,
    page_size?: number,
    construction?: boolean,
  ): Observable<any> {
    const qry: any = {
      page: page || 1,
      page_size: page_size || 15,
    };
    if (construction !== undefined && construction !== null) {
      qry.with_construction = construction;
    }
    if (!!id || !!company) {
      qry.user_id = id;
      qry.company_id = company;

      return this.apiService.getRemove<Array<any>>(null, 'projects/', qry, 'get', true).pipe(
        tap((data: any) => {
          data.data.map((project: any) => {
            project.url = this.createProjectUrl(
              project.construction_projects[0]?._id as string,
              project.construction_projects[0]?.last_finished_segment,
            );
          });
        }),
      );
    } else {
      return this.apiService.getRemove<Array<any>>(null, 'projects/', qry, 'get', true).pipe(
        tap((data: any) => {
          data.data.map((project: any) => {
            project.url = this.createProjectUrl(
              project.construction_projects[0]?._id as string,
              project.construction_projects[0]?.last_finished_segment,
            );
          });
        }),
      );
    }
  }

  /**
   * Function for getting array of pinned project for specific user
   *
   * @param: id: string
   * @return Observable<any>
   */
  public getPinnedProjects(id: string) {
    return this.http.get<PaginatedResponse<{ construction_project_id: string }>>(
      `${environment.apiUrl}/${environment.apiVersion}/pin_construction_projects/`,
      {
        params: { user_id: id },
      },
    );
  }

  /**
   * Function for creating project url based on
   * construction project data
   * @param: constructionProjectId: string
   * @param: status: string | undefined
   * @return string | undefined
   */
  public createProjectUrl(constructionProjectId: string, status: string | undefined): string | undefined {
    const isProjectStarted =
      LastFinishedSegmentEnum.verification === status ||
      ProjectStatusEnum.CONSTRUCTION_STARTED === status ||
      ProjectStatusEnum.IN_SERVICE === status;

    const route =
      isProjectStarted && ProjectStatusEnum.IN_SERVICE === status ? 'qc-issues/list-view' : 'progress-tracking/map';

    return `/construction/projects/${constructionProjectId}/${isProjectStarted ? route : 'configuration'}`;
  }

  /**
   * Function for getting array of pinned project for specific user
   *
   * @param: id: string
   * @return Observable<any>
   */
  public pinProject(id: string, pinned: boolean): Observable<PaginatedResponse<ProjectModel>> {
    return this.http.put<PaginatedResponse<ProjectModel>>(
      `${environment.apiUrl}/${environment.apiVersion}/pin_construction_projects/${id}/`,
      { is_pinned: pinned },
    );
  }

  public getSingleProject(id: string | null | undefined): Observable<{
    data: ProjectModel;
  }> {
    return this.apiService.getRemove<any>(null, `projects/${id}/`, {}, 'get', true);
  }
}
