import { BusinessRole } from '../services/common/business-roles.service';

export type BusinessRoleViewContex = Record<BusinessRole, string[]>;

export class BusinessRoleContextBuilder {
  private readonly context: BusinessRoleViewContex;

  constructor() {
    this.context = {
      [BusinessRole.SuperAdmin]: [],
      [BusinessRole.CompanyAdminOwnCompanyContent]: [],
      [BusinessRole.CompanyUserOwnUserContent]: [],
      [BusinessRole.CompanyUserOwnCompanyContent]: [],
      [BusinessRole.CompanyUserOtherCompanyUserContent]: [],
      [BusinessRole.CompanyAdminOtherCompanyUserContent]: [],
    };
  }

  public superAdminContent(permissions: string[]): BusinessRoleContextBuilder {
    this.context[BusinessRole.SuperAdmin] = permissions;

    return this;
  }

  public adminOwnCompanyContent(permissions: string[]): BusinessRoleContextBuilder {
    this.context[BusinessRole.CompanyAdminOwnCompanyContent] = permissions;

    return this;
  }

  public userOwnUserContent(permissions: string[]): BusinessRoleContextBuilder {
    this.context[BusinessRole.CompanyUserOwnUserContent] = permissions;

    return this;
  }

  public userOwnCompanyContent(permissions: string[]): BusinessRoleContextBuilder {
    this.context[BusinessRole.CompanyUserOwnCompanyContent] = permissions;

    return this;
  }

  public userOtherUserContent(permissions: string[]): BusinessRoleContextBuilder {
    this.context[BusinessRole.CompanyUserOwnUserContent] = permissions;

    return this;
  }

  public adminOtherUserContent(permissions: string[]): BusinessRoleContextBuilder {
    this.context[BusinessRole.CompanyAdminOtherCompanyUserContent] = permissions;

    return this;
  }

  public build(): BusinessRoleViewContex {
    return this.context;
  }
}
