<div class="file-upload-component">
  <input
    [id]="id"
    type="file"
    hidden
    [accept]="fileTypes"
    (change)="validationAndUpload($event)"
    [multiple]="multiple"
    [formControl]="control"
    style="display: none !important"
  />

  <button tbs-button tbsType="text" tbsIconPosition="left" (click)="triggerClick()">
    <i class="ci-upload-24"></i>{{ title }}
  </button>

  <span class="note {{ noteClass }}" *ngIf="note" [innerHTML]="note"></span>
  <span class="mat-error tb-caption" *ngIf="errorMessage">{{ errorMessage }}</span>
</div>
