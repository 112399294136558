import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type RoleWithPermissions = {
  name: string;
  permissions: string[];
};

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private rolesWithPermissionsSubject$ = new BehaviorSubject<Map<string, string[]>>(new Map());
  private viewContextPermissionsSubject$ = new BehaviorSubject<Map<string, string[]>>(new Map());
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public rolesWithPermissions$ = this.rolesWithPermissionsSubject$.asObservable();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public roles$ = this.rolesWithPermissions$.pipe(
    map((rolesWithPermissions) => {
      const roles = [];
      for (const key of rolesWithPermissions.keys()) {
        roles.push(key);
      }

      return roles;
    })
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public permissions$ = combineLatest([
    this.rolesWithPermissions$,
    this.viewContextPermissionsSubject$.asObservable(),
  ]).pipe(
    map(([rolesWithPermissions, viewContextPermissions]) => {
      const permissions = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of rolesWithPermissions.entries()) {
        permissions.push(...value);
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of viewContextPermissions.entries()) {
        permissions.push(...value);
      }

      return permissions;
    })
  );

  public setRolesWithPermissions(rolesWithPermissions: RoleWithPermissions[]): void {
    const roleWithPermissionsMap = new Map<string, string[]>();
    for (const roleWithPermissions of rolesWithPermissions) {
      roleWithPermissionsMap.set(roleWithPermissions.name, roleWithPermissions.permissions);
    }
    this.rolesWithPermissionsSubject$.next(roleWithPermissionsMap);
  }

  public setViewContextPermissions(viewContextKey: string, permissions: string[]): void {
    const current = this.viewContextPermissionsSubject$.getValue();
    current.set(viewContextKey, permissions);
    this.viewContextPermissionsSubject$.next(current);
  }

  public hasRoles(roles: string[]): Observable<boolean> {
    return this.roles$.pipe(map((rolesMap) => roles.some((role) => rolesMap.includes(role))));
  }

  public hasPermissions(permissions: string[]): Observable<boolean> {
    return this.permissions$.pipe(
      map((permissionsMap) => permissions.some((permission) => permissionsMap.includes(permission)))
    );
  }

  public flushRolesAndPermissions(): void {
    this.rolesWithPermissionsSubject$.next(new Map());
    this.viewContextPermissionsSubject$.next(new Map());
  }
}
