<tbs-dialog tbsSeparator="top">
  <tbs-dialog-header tbsHeading="HTTP Error" tbsType="error"></tbs-dialog-header>

  <mat-dialog-content>
    <p>
      There was an error on our side. Please refresh page and try again. If the problem persist, contact Terabase
      support on construct&#64;terabase.energy.
    </p>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="flex-gap-10">
    <button mat-dialog-close tbs-button tbsType="ghost" (click)="refresh()">Refresh</button>
    <button mat-dialog-close tbs-button (click)="close()">Close</button>
  </mat-dialog-actions>
</tbs-dialog>
