import { ContactProfileModel } from 'src/app/user/user.component';

export enum PRIMARY_DATA_SOURCE {
  owner = 'owner',
  epc = 'epc',
}

export enum LastFinishedSegmentEnum {
  initial = '0:initial',
  general = '1:general',
  schedule = '2:schedule',
  digitalTwin = '3:digital_twin',
  documentation = '4:documentation',
  verification = '5:verification',
}

export enum DictionaryOfLabelsColumn {
  singular = 'singular',
  plural = 'plural',
  abbreviation = 'abbreviation',
  item = 'name',
  weight = 'weight',
  include = 'include',
}

export enum ProjectRoleTypeEnum {
  OwnerConstructionManager = 'owner_construction_manager',
  EPCQualityManager = 'epc_quality_manager',
  EPCCRQualityManagers = 'epc_cr_quality_manager',
  Other = 'other_role',
}

export type TeamMember = {
  contact_profile_id: string | undefined;
  contact_profile: ContactProfileModel;
  project_role: ProjectRoleTypeEnum | DailyDigestTypeEnum;
  role: string;
};

export enum DailyDigestTypeEnum {
  OwnerConstructionManager = 'owner_construction_manager',
  EPCQualityManager = 'epc_quality_manager',
  EPCCRQualityManagers = 'epc_cr_quality_manager',
  Other = 'other_role',
  SuperAdmin = 'superadmin',
}

export enum DroneOrthoStatusText {
  notInitiated = 'Not initiated',
  processing = 'Processing',
  adDone = 'AD Done',
  reviewed = 'Reviewed',
}

export enum DroneOrthosStatus {
  processing = 'processing',
  adDone = 'done',
  reviewed = 'reviewed',
}

export enum DroneOrthoStatusColor {
  notInitiated = 'gray',
  processing = 'orange',
  adDone = 'blue',
  reviewed = 'green',
}

export enum DroneOrthoStatusEnum {
  checked = 'checked',
  notChecked = 'not_checked',
}

export enum ConstructionProjectCardsViewEnum {
  GRID = 'grid',
  LIST = 'list',
}

export enum WorkWeekDisplayEnum {
  calendar = 'calendar',
  project = 'project',
}
