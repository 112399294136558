import { Pipe, PipeTransform } from '@angular/core';
import {
  ConstructionProjectFeaturesNameEnum,
  ConstructionProjectFeaturesValuesEnum,
} from '@construction/construction.enums';

@Pipe({
    name: 'featureNamePipe',
    standalone: false
})
export class FeatureNamePipePipe implements PipeTransform {
  public transform(value: string): string {
    switch (value) {
      case ConstructionProjectFeaturesValuesEnum.ADVANCED_DETECTION:
        return ConstructionProjectFeaturesNameEnum.ADVANCED_DETECTION;
      case ConstructionProjectFeaturesValuesEnum.APPROVALS:
        return ConstructionProjectFeaturesNameEnum.APPROVALS;
      case ConstructionProjectFeaturesValuesEnum.DIGITAL_TWIN:
        return ConstructionProjectFeaturesNameEnum.DIGITAL_TWIN;
      case ConstructionProjectFeaturesValuesEnum.PRODUCTION_TRACKING:
        return ConstructionProjectFeaturesNameEnum.PRODUCTION_TRACKING;
      case ConstructionProjectFeaturesValuesEnum.EQUIPMENT_TRACKING:
        return ConstructionProjectFeaturesNameEnum.EQUIPMENT_TRACKING;
      case ConstructionProjectFeaturesValuesEnum.LOGISTICS_INVENTORY:
        return ConstructionProjectFeaturesNameEnum.LOGISTICS_INVENTORY;
      case ConstructionProjectFeaturesValuesEnum.MV_CIRCUIT:
        return ConstructionProjectFeaturesNameEnum.MV_CIRCUIT;
      case ConstructionProjectFeaturesValuesEnum.OMEGA:
        return ConstructionProjectFeaturesNameEnum.OMEGA;
      case ConstructionProjectFeaturesValuesEnum.QC_ISSUES:
        return ConstructionProjectFeaturesNameEnum.QC_ISSUES;
      case ConstructionProjectFeaturesValuesEnum.TERAFAB:
        return ConstructionProjectFeaturesNameEnum.TERAFAB;
      default:
        return value;
    }
  }
}
