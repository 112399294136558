import { Injectable } from '@angular/core';
import { DateTimeFormatEnum } from '@core/core.enum';
import { TimeZoneService } from '@ngx-terabase/ui/core/services';
import moment from 'moment';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  //Dont know if needed
  public currentTimeInZone(): Moment {
    return moment().tz(TimeZoneService.timeZone);
  }

  //Rename it
  public today(): Moment {
    return moment().tz(TimeZoneService.timeZone).startOf('day');
  }

  //Make for our uses endOfToday
  public endOfToday(): Moment {
    return moment().tz(TimeZoneService.timeZone, true).endOf('day');
  }

  //Check if needed
  public dateToMomentWithZone(date: Date, keepLocalTime: boolean) {
    return moment(date).tz(TimeZoneService.timeZone, keepLocalTime);
  }

  //Make it more intuitive with keepLocalTime e.g. always true and send false if its only string
  public stringToMomentWithZone(date: string | Date | undefined, keepLocalTime: boolean) {
    return moment(date).tz(TimeZoneService.timeZone, keepLocalTime);
  }

  //Maybe take out or make momentToDate method
  public stringToDateWithZone(date: string | undefined, keepLocalTime: boolean) {
    return moment(date).tz(TimeZoneService.timeZone, keepLocalTime).toDate();
  }

  //Leave it
  public ticksToMomentWithZone(date: number, keepLocalTime: boolean) {
    return moment(date).tz(TimeZoneService.timeZone, keepLocalTime);
  }

  //Take out
  public tz(date: Moment, keepLocalTime: boolean) {
    return moment(date).tz(TimeZoneService.timeZone, keepLocalTime);
  }

  public tableDateComparator(filterLocalDateAtMidnight: Date, cellValue: Moment, withTimeZone = true) {
    const date = moment(cellValue);
    const cellDateValue = date.format(DateTimeFormatEnum.ShortDate);
    const filterDate = withTimeZone
      ? moment(filterLocalDateAtMidnight).tz(TimeZoneService.timeZone, true).format(DateTimeFormatEnum.ShortDate)
      : moment(filterLocalDateAtMidnight).format(DateTimeFormatEnum.ShortDate);

    if (filterDate === cellDateValue) {
      return 0;
    }
    if (cellDateValue.valueOf() < filterDate.valueOf()) {
      return -1;
    }
    if (cellDateValue.valueOf() > filterDate.valueOf()) {
      return 1;
    }

    return 1;
  }

  public sortDates(dateA: Moment, dateB: Moment) {
    if (dateA.isSame(dateB)) return 0;

    return dateA.isAfter(dateB) ? 1 : -1;
  }
}
