import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RolesService } from '../../../permission/services/roles.service';
import { UserInterface } from '../../interfaces/user.interface';
import { BusinessRoleViewContex } from '../../permissions/business-role-context.builder';
import { BusinessRole, BusinessRolesService } from '../common/business-roles.service';
import { ConstructionProjectLoadModel } from '@construction/models/construction-project.model';
import { ProjectRolesService } from '../common/project-roles.service';
import { ProjectRoleViewContex } from '@core/permissions/project-role-context.builder';
import { UserService } from './user.service';

export enum ViewContextKey {
  AboutPage = 'AboutPage',
  CompanyPage = 'CompanyPage',
  UserFormComponent = 'UserFormComponent',
  ResponsibilityPage = 'ResponsibilityPage',
  ContactsPage = 'ContactsPage',
  LogisticsPage = 'LogisticsPage',
  ProjectContext = 'ProjectContext',
}

export enum ProjectRoles {
  Owner = 'owner',
  Epc = 'epc',
  Supplier = 'supplier',
}

@Injectable({
  providedIn: 'root',
})
export class ViewContextService {
  constructor(
    private businessRoleService: BusinessRolesService,
    private rolesService: RolesService,
    private projectRoleService: ProjectRolesService,
    private userService: UserService
  ) {}

  public setBusinessRoleViewContextPermissions(
    contextKey: ViewContextKey,
    context: BusinessRoleViewContex,
    userToCompare?: UserInterface
  ): Observable<BusinessRole> {
    return this.businessRoleService
      .getBusinessRole(userToCompare)
      .pipe(tap((businessRole) => this.rolesService.setViewContextPermissions(contextKey, context[businessRole])));
  }

  public setProjectRoleViewContextPermissions(
    contextKey: ViewContextKey,
    roleContext: ProjectRoleViewContex,
    companyContext: ProjectRoleViewContex,
    project: ConstructionProjectLoadModel
  ) {
    return combineLatest([
      this.userService.currentUser.pipe(map((userData) => userData.data)),
      this.projectRoleService.getUserProjectRole(),
    ]).pipe(
      tap(([user, projectRole]) => {
        const isOwner = project.project?.owner_company._id === user.company_profile?._id;
        const isEpc = project.epc_company === user.company_profile?._id;

        const ownerCompanyPermissions = isOwner ? companyContext.owner : [];
        const epcCompanyPermissions = isEpc ? companyContext.epc : [];

        const permissions = [...roleContext[projectRole], ...ownerCompanyPermissions, ...epcCompanyPermissions];

        this.rolesService.setViewContextPermissions(contextKey, permissions);
      })
    );
  }
}
