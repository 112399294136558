import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FirstLoginGuard {
  private firstLoginFinished = false;

  constructor(private router: Router) {}

  public canActivate(): boolean | UrlTree {
    if (!this.firstLoginFinished) {
      return this.router.createUrlTree(['user', 'profile']);
    }

    return true;
  }

  public setFirstLoginFinished(finished: boolean) {
    this.firstLoginFinished = finished;
  }
}
