import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { QcObservationsFilterParams } from '@construction/modules/qc-issues/qc-issues.types';
import { DateService } from '@core/services/common/date-service';
import { FilterModel, SortModelItem } from 'ag-grid-community';

@Injectable({
  providedIn: 'root',
})
export class ServerSideTableService {
  private dateService = inject(DateService);

  //Possibly, we will have to update this map for future multi-filter columns
  private defaultValues = new Map([
    ['assignee', { setKey: 'assigned_profile_names', textKey: 'assigned_profile_full_name' }],
    ['full_name', { setKey: 'names', textKey: 'full_name' }],
  ]);

  public addFiltersToParams(filters: FilterModel, params: HttpParams) {
    Object.keys(filters).forEach((key) => {
      switch (filters[key].filterType) {
        case 'date': {
          const dateInZone = this.dateService
            .stringToMomentWithZone(filters[key].dateFrom, true)
            .startOf('day')
            .utc()
            .format();

          return (params = params.append(key, dateInZone));
        }
        case 'text':
          return (params = params.append(key, filters[key].filter));
        case 'set':
          return (params = params.append(key, filters[key].values.join(',').toLowerCase()));
        case 'multi':
          this.getMultiFilterValues(filters, key).forEach((filter) => {
            params = params.append(filter.key, filter.value);
          });

          return params;
        default:
          return;
      }
    });

    return params;
  }

  public changeSortColumnsName(sortModel: SortModelItem): string {
    switch (sortModel.colId) {
      case 'inspection_key':
        return 'inspection_number';
      case 'statuses':
        return 'status';
      case 'qc_subscope_uuids':
        return 'qc_subscope';
      case 'qc_scope_uuids':
        return 'qc_scope';

      default:
        return sortModel.colId;
    }
  }

  public addPayloadData(filters: FilterModel, payload: QcObservationsFilterParams) {
    Object.keys(filters).forEach((key) => {
      switch (filters[key].filterType) {
        case 'date': {
          const dateInZone = this.dateService
            .stringToMomentWithZone(filters[key].dateFrom, true)
            .startOf('day')
            .utc()
            .format();

          return (payload = { ...payload, [key]: dateInZone });
        }
        case 'text':
          return (payload = { ...payload, [key]: filters[key].filter });
        case 'set':
          return (payload = { ...payload, [key]: filters[key].values.join(',').toLowerCase() || null });
        case 'multi':
          this.getMultiFilterValues(filters, key).forEach((filter) => {
            payload = { ...payload, [filter.key]: filter.value };
          });

          return payload;
        default:
          return;
      }
    });

    return payload;
  }

  private getMultiFilterValues(filters: FilterModel, key: string) {
    const filter = filters[key];
    const multiFilterValues: any[] = [];

    const filterKeys = this.defaultValues.get(key);

    filter.filterModels.forEach((model: any) => {
      if (!model) {
        return;
      }

      if (model.filterType === 'text')
        multiFilterValues.push({ key: filterKeys ? filterKeys.textKey : key, value: model.filter });
      if (model.filterType === 'set')
        multiFilterValues.push({ key: filterKeys ? filterKeys.setKey : key, value: model.values.join(',') || null });
    });

    return multiFilterValues;
  }
}
