import { Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TbsButtonModule } from '@ngx-terabase/ui/button';
import { TbsDialogModule } from '@ngx-terabase/ui/dialog';

@Component({
    selector: 'error-response-dialog',
    templateUrl: './error-response-dialog.component.html',
    styleUrls: ['./error-response-dialog.component.scss'],
    imports: [MatDialogModule, TbsDialogModule, TbsButtonModule],standalone: true
})
export class ErrorResponseDialogComponent {
  private dialogRef: MatDialogRef<ErrorResponseDialogComponent> = inject(MatDialogRef<ErrorResponseDialogComponent>);

  public refresh(): void {
    window.location.reload();
  }

  public close(): void {
    this.dialogRef.close();
  }
}
