import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import _ from 'lodash';
import { ErrorService } from 'src/app/@core/services/common/error.service';
import { UserRole, UserService } from 'src/app/@core/services/rest/user.service';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import { imageHelper } from 'src/app/@marketplace/shared/utils/helpers';
import { CompanyService } from '../../../@core/services/rest/company.service';
import { SubSink } from 'subsink';
import { AuthenticationService } from 'src/app/@core/services/rest/authentication.service';
import { ViewContextKey, ViewContextService } from 'src/app/@core/services/rest/view-context.service';
import { LocalStorageService } from 'src/app/@core/services/common/local-storage.service';
import { LocalStorageKeyEnum } from '../../../@core/enums/local-storage-key.enum';
import { UserInterface } from 'src/app/@core/interfaces/user.interface';
import { PermissionCollection } from 'src/app/@core/permissions/permission.collection';
import {
  userFormProfileViewContextPermissions,
  userFormContactsViewContextPermissions,
} from 'src/app/@core/permissions/view-contexts';
import { UserFormValidator } from '../validators/user-form.validator';
import { FirstLoginGuard } from '@core/services/guards/first-login.guard';
import { ImagesService } from '@core/services/common/images.service';

const userRoles: Record<string, { text: string; value: string[] }> = {
  superadmin: {
    text: 'Superadmin',
    value: [UserRole.SuperAdmin],
  },
  company_admin: {
    text: 'Company Admin',
    value: [UserRole.CompanyAdmin, UserRole.CompanyUser],
  },
  company_user: {
    text: 'User',
    value: [UserRole.CompanyUser],
  },
};

@Component({
  selector: 'core-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public data: UserInterface;
  @Input() public chat = false;
  @Input() public codingList: any;
  @Input() public redirect?: string;
  @Input() public profilePicture?: any;
  @Input() public dialog = false;
  @Input() public submitForm = false;
  @Input() public context = 'profile';
  @Output() public formChanged = new EventEmitter<boolean>();

  public userForm!: UntypedFormGroup;
  public userRole = '';
  public roles: any = [];
  public regions: any = [];
  public positions: any = [];
  public submitted = false;
  public spinnerFullscreen = false;
  public currentUser$: Observable<any> = this.userService.currentUser;
  public kamForCompanies = '';
  public deleteProfileImage = false;
  public kamForCompaniesChecked = false;
  public profileBlob: any;
  public permission = PermissionCollection;
  private subs = new SubSink();

  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private errorService: ErrorService,
    private spinner: NgxSpinnerService,
    public companyService: CompanyService,
    private imageService: ImagesService,
    private authService: AuthenticationService,
    private viewContextService: ViewContextService,
    private localStorage: LocalStorageService,
    private firstLoginGuard: FirstLoginGuard,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.submitForm && changes.submitForm.currentValue) {
      this.submit();
    }
  }

  public mapPositions(): any {
    return _.map(this.codingList?.title_coding_list.data, (title) => {
      const position: any = {
        value: title._id,
        text: title.value,
      };

      return position;
    });
  }

  public mapRegions(): any {
    return _.map(this.codingList?.sales_region_coding_list.data, (title) => {
      const region: any = {
        value: title._id,
        text: title.value,
      };

      return region;
    });
  }

  public ngOnInit(): void {
    const viewContext =
      this.context === 'profile' ? userFormProfileViewContextPermissions : userFormContactsViewContextPermissions;
    this.subs.sink = this.viewContextService
      .setBusinessRoleViewContextPermissions(ViewContextKey.UserFormComponent, viewContext, this.data)
      .subscribe();
    this.kamForCompanies = this.setKamCompaniesNames();
    this.userForm = this.formBuilder.group(
      {
        email: [this.data?.email, Validators.required],
        roles: [this.data.roles ? this.getUserRoleName(this.data.roles) : null, Validators.required],
        first_name: [this.data?.contact_profile?.first_name, Validators.required],
        last_name: [this.data?.contact_profile?.last_name, Validators.required],
        position: [this.data?.contact_profile?.position],
        video_introduction: [this.data?.contact_profile?.video_introduction],
        sales_region: [this.data?.contact_profile?.sales_region],
        description: '',
        key_account_manager: [this.kamForCompanies],
        phone_number: [this.data?.contact_profile?.phone_number],
        profile_picture: null,
      },
      {
        validator: [UserFormValidator.userFormValidator(['roles', 'sales_region', 'position'], this.errorService)],
      },
    );

    this.regions = this.mapRegions();
    this.positions = this.mapPositions();
    this.roles = this.getUserRoles();
    this.userRole = this.getUserRoleName(this.data.roles);

    this.spinnerFullscreen = this.router.url.indexOf('profile') !== -1;

    if (this.data?.contact_profile?.profile_picture?._id) {
      this.imageService.getPresignedUrlImage(this.data?.contact_profile?.profile_picture?._id).subscribe((res) => {
        this.profilePicture = res.url;
      });
    }

    if (this.localStorage.getItem(LocalStorageKeyEnum.UserImage)) {
      this.userForm.markAsDirty();
      this.deleteProfileImage = true;
    }

    this.subs.sink = this.userForm.valueChanges.subscribe(() => {
      if (this.userForm.dirty) {
        this.emitChanged(true);
      }
    });
  }

  public uploadProfilePicture(image: any): void {
    this.profilePicture = image.base64;
    this.profileBlob = image;

    imageHelper.saveTemporaryImage(LocalStorageKeyEnum.UserImage, image);
    this.deleteProfileImage = true;
  }

  public deleteUploadedImage(): void {
    this.profilePicture = undefined;
    this.localStorage.removeItem(LocalStorageKeyEnum.UserImage);
    this.deleteProfileImage = false;
  }

  public submit(): void {
    if (!this.userForm.invalid) {
      this.userForm.value.first_login_finished = true;
      this.firstLoginGuard.setFirstLoginFinished(true);
      this.userForm.value.profile_picture = this.profileBlob?.binary;
      this.spinner.show(this.data._id);
      this.subs.sink = this.updateUser(this.userForm.value, this.data._id).subscribe(() => {
        // if user is updating self, refresh current user subject
        if (this.userService.user._id === this.data._id) {
          this.authService.refreshCurrentUser();
        }
        this.submitted = true;
        this.subs.sink = this.userForm.valueChanges.subscribe(() => {
          this.submitted = false;
        });

        this.localStorage.removeItem(LocalStorageKeyEnum.UserImage);
        this.deleteProfileImage = false;
        this.spinner.hide(this.data._id);
        this.emitChanged(false);
        this.redirect && this.router.navigate([this.redirect]);
      });
    }
  }

  public changeEnablement(): void {
    this.spinner.show(this.data._id);
    this.subs.sink = this.userService
      .changeUserAvailability(this.data.disabled, this.data._id)
      .subscribe((res: any) => {
        this.data = res.data;
        this.spinner.hide(this.data._id);
      });
  }

  public keyAccountManagerChange(event: any): void {
    if (event.checked) {
      this.subs.sink = this.userService.addKeyAccountManager(this.data.contact_profile._id).subscribe((data: any) => {
        this.data.contact_profile = { ...data.data };
        this.kamForCompanies = this.setKamCompaniesNames();
      });
    } else {
      this.subs.sink = this.userService
        .removeKeyAccountManager(this.data.contact_profile._id)
        .subscribe((data: any) => {
          this.data.contact_profile = { ...data.data };
          this.kamForCompanies = this.setKamCompaniesNames();
        });
    }
  }

  public setKamCompaniesNames(): any {
    return _.join(
      _.map(this.data.contact_profile.kam_for_companies, (company) => {
        this.subs.sink = this.currentUser$
          .pipe(
            tap((user: any) => {
              const exists =
                _.indexOf(
                  _.map(this.data.contact_profile.kam_for_companies, (comp) => {
                    return comp._id;
                  }),
                  user.data.contact_profile.company,
                ) !== -1;
              if (exists) {
                this.kamForCompaniesChecked = true;
              }
            }),
          )
          .subscribe();

        return company.company_name;
      }),
      ', ',
    );
  }

  public emitChanged(value: boolean) {
    this.formChanged.emit(value);
  }

  public get firstName(): AbstractControl {
    return this.userForm.get('first_name') as AbstractControl;
  }

  public get lastName(): AbstractControl {
    return this.userForm.get('last_name') as AbstractControl;
  }

  public get email(): AbstractControl {
    return this.userForm.get('email') as AbstractControl;
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.localStorage.removeItem(LocalStorageKeyEnum.UserImage);
  }

  private updateUser(userData: { roles: any }, userId: any) {
    const formData = new FormData();
    userData.roles = userRoles[userData.roles].value;

    _.forEach(userData, (value, index) => {
      if (index === 'roles') {
        _.forEach(value, (v, i: any) => {
          formData.set(`${index}[${i}]`, v);
        });
      } else {
        if (value === null) {
          formData.set(index, '');
        } else {
          formData.set(index, value);
        }
      }
    });

    return this.userService.updateUser(formData, userId);
  }

  private getUserRoleName(roles: string[]): string {
    let role = '';
    _.each(userRoles, (userRole, index) => {
      if (_.isEqual(userRole.value.sort(), roles.sort())) {
        role = index;
      }
    });

    return role;
  }

  private getUserRoles() {
    return _.filter(
      _.map(userRoles, (userRole, name) => {
        let role: any = {};
        if (name !== UserRole.SuperAdmin || this.userService.isSuperAdmin()) {
          role = {
            text: userRole.text,
            value: name,
          };

          return role;
        }

        return null;
      }),
      (role) => !!role,
    );
  }
}
