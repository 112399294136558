<div class="c--core-textarea {{ size }}">
  <mat-label class="mat-body-2 c--core-input-label">{{ label }}</mat-label>
  <mat-form-field [floatLabel]="'never'" hideRequiredMarker class="textarea">
    <!-- TODO: REFACTOR -->
    <textarea
      matInput
      [style]="custom"
      class="mat-body-1"
      [readonly]="readonly"
      [required]="required"
      [formControl]="control"
      [placeholder]="placeholder"
      [(ngModel)]="predefinedValue"
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
      [cdkTextareaAutosize]="autosize"
      [ngClass]="{ 'no-scroll': autosize }"
      [class.readonly]="readonly"
    ></textarea>
    <!-- TODO: REFACTOR Error messages, create another component-->
    <mat-error
      *ngIf="
        control !== undefined &&
        (control.hasError('required') || control.hasError('minlength') || control.hasError('pattern'))
      "
      class="mat-caption"
    >
      {{ errorMessage }}
    </mat-error>
  </mat-form-field>
</div>
