import { Component, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/@core/services/common/local-storage.service';
import { UserService } from 'src/app/@core/services/rest/user.service';
import { SubSink } from 'subsink';
import { LocalStorageKeyEnum } from '../../../@core/enums/local-storage-key.enum';

@Component({
    selector: 'user-profile-dialog',
    templateUrl: './user-profile-dialog.component.html',
    styleUrls: ['./user-profile-dialog.component.scss'],
    standalone: false
})
export class UserProfileDialogComponent implements OnInit, OnDestroy {
  public user: any;
  public coding_lists: any;
  public submit = false;
  public formDirty = false;
  private subs = new SubSink();

  constructor(private userService: UserService, private localStorage: LocalStorageService) {}

  public ngOnInit(): void {
    this.subs.sink = this.userService.currentUser.subscribe((user) => {
      this.user = user.data;
    });
  }

  public submitDialog() {
    this.submit = true;
    this.formDirty = false;
  }

  public valueChanged(event: any) {
    this.formDirty = event;
    this.submit = false;
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.localStorage.removeItem(LocalStorageKeyEnum.UserImage);
  }
}
