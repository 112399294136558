import { BusinessRoleContextBuilder } from '../business-role-context.builder';
import { PermissionCollection } from '../permission.collection';

export const aboutViewContextPermissions = new BusinessRoleContextBuilder()
  .superAdminContent([
    PermissionCollection.uploadImage,
    PermissionCollection.deleteImage,
    PermissionCollection.addHeadline,
    PermissionCollection.uploadLargerImage,
    PermissionCollection.deleteLargerImage,
    PermissionCollection.addDescription,
    PermissionCollection.addWebsite,
    PermissionCollection.verifySupplier,
    PermissionCollection.addProductionCapacity,
    PermissionCollection.addTotalPipeline,
    PermissionCollection.addKeyManLocation,
    PermissionCollection.addGeoFocus,
    PermissionCollection.submitBidToPlatform,
    PermissionCollection.createCompanyProfile,
    PermissionCollection.enableDisableCompanyProfile,
  ])
  .adminOwnCompanyContent([
    PermissionCollection.uploadImage,
    PermissionCollection.deleteImage,
    PermissionCollection.addHeadline,
    PermissionCollection.uploadLargerImage,
    PermissionCollection.deleteLargerImage,
    PermissionCollection.addDescription,
    PermissionCollection.addWebsite,
    PermissionCollection.addProductionCapacity,
    PermissionCollection.addTotalPipeline,
    PermissionCollection.addKeyManLocation,
    PermissionCollection.addGeoFocus,
    PermissionCollection.submitBidToPlatform,
    PermissionCollection.createCompanyProfile,
  ])
  .build();
