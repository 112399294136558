<form [formGroup]="userForm" *ngIf="(currentUser$ | async)?.data as user">
  <div class="user-basics">
    <div class="user-profile">
      <div class="user-profile-image">
        <img *ngIf="profilePicture" [src]="profilePicture" alt="profile image" />
      </div>
      <core-file-upload
        *ngIf="[permission.userFormUploadImage] | can | async"
        [fileSize]="50000"
        [id]="data?._id"
        [dimensions]="[200, 200]"
        [title]="'Upload Image'"
        [noteClass]="'mat-caption'"
        (emitFile)="uploadProfilePicture($event)"
        [fileUploadTitle]="'Company Logo'"
        [control]="userForm?.get('profile_picture')"
        [fileTypes]="['image/png', 'image/jpg', 'image/jpeg']"
        [custom]="{ padding: 0, 'justify-content': 'flex-start', 'margin-top': '8px' }"
        [note]="'* Picture should be 200 x 200 px, up to 50kb and in .jpg or .png format.'"
      >
      </core-file-upload>

      <button
        (click)="deleteUploadedImage()"
        class="delete-uploaded-image"
        *ngIf="([permission.userFormUploadImage] | can | async) && profilePicture !== undefined && deleteProfileImage"
        tbs-button
        tbsType="text"
        tbsIconPosition="left"
      >
        <i class="ci-trash-24"></i>Delete Image
      </button>
    </div>
    <div>
      <div
        class="form-control"
        *ngIf="(companyService.company | async) !== null && router.url.indexOf('profile') !== -1"
      >
        <tbs-input tbsSize="large">
          <mat-label class="mat-body-2">Company Name</mat-label>
          <mat-form-field floatLabel="never">
            <input
              matInput
              formControlName="name"
              readonly="true"
              [defaultValue]="(companyService.company | async).company_name"
            />
          </mat-form-field>
        </tbs-input>
      </div>
      <div class="form-control">
        <tbs-input>
          <mat-label class="mat-body-2">First Name</mat-label>
          <mat-form-field floatLabel="never">
            <input
              matInput
              formControlName="first_name"
              placeholder="Your First Name"
              [readonly]="([permission.userFormViewFirstLastName] | can | async) === false"
            />
            <mat-error *ngIf="firstName.hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </tbs-input>
      </div>

      <div class="form-control">
        <tbs-input>
          <mat-label class="mat-body-2">Last Name</mat-label>
          <mat-form-field floatLabel="never">
            <input
              matInput
              formControlName="last_name"
              placeholder="Your Last Name"
              [readonly]="([permission.userFormViewFirstLastName] | can | async) === false"
            />
            <mat-error *ngIf="lastName.hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </tbs-input>
      </div>

      <div class="form-control">
        <tbs-input>
          <mat-label class="mat-body-2">Email</mat-label>
          <mat-form-field floatLabel="never">
            <input matInput formControlName="email" placeholder="Your Email" readonly="true" />
            <mat-error *ngIf="email.hasError('required')">This field is required</mat-error>
          </mat-form-field>
        </tbs-input>
      </div>

      <div class="form-control">
        <div class="form-label">Title</div>
        <tbs-input>
          <mat-form-field floatLabel="never">
            <input
              matInput
              formControlName="position"
              placeholder="Please enter title"
              [readonly]="([permission.userFormViewRole] | can | async) === false"
            />
          </mat-form-field>
        </tbs-input>
      </div>

      <div class="start-chat-button" *ngIf="([permission.userFormStartChat] | can | async) && !dialog">
        <button tbs-button tbsIconPosition="left"><i class="ci-speech-bubble-24"></i>Start Chat</button>
      </div>
    </div>
  </div>

  <div class="form-control description">
    <label class="form-label">Description</label>
    <core-textarea
      [name]="'description'"
      [control]="userForm.controls.description"
      placeholder="Add a few words about yourself"
      [predefinedValue]="data?.contact_profile?.description"
      [autosize]="false"
      [custom]="{ height: '128px' }"
      [readonly]="([permission.userFormEditDescription] | can | async) === false"
    ></core-textarea>
  </div>

  <div class="form-control label-left">
    <label class="form-label">Video Introduction:</label>

    <tbs-input>
      <mat-form-field floatLabel="never">
        <input
          matInput
          formControlName="video_introduction"
          placeholder="Copy URL here"
          [readonly]="([permission.userFormEditVideoIntro] | can | async) === false"
        />
      </mat-form-field>
    </tbs-input>
  </div>

  <div class="form-control label-left" *ngIf="router.url.indexOf('profile') === -1">
    <label class="form-label">Geographical focus</label>

    <tbs-input>
      <mat-form-field floatLabel="never">
        <input
          matInput
          formControlName="sales_region"
          placeholder="Please enter geographical focus"
          [readonly]="([permission.userFormEditSalesRegion] | can | async) === false"
        />
      </mat-form-field>
    </tbs-input>
  </div>

  <div
    *ngIf="([permission.userFormViewKeyAccountManager] | can | async) && kamForCompanies.length > 0"
    class="form-control label-left"
  >
    <label class="form-label">Key Account Manager:</label>

    <tbs-input>
      <mat-form-field floatLabel="never">
        <input matInput formControlName="key_account_manager" placeholder="Enter for what company" readonly="true" />
      </mat-form-field>
    </tbs-input>
  </div>
  <div class="form-control" *ngIf="([permission.userFormMakeMyKeyAccountManager] | can | async) && !dialog">
    <mat-slide-toggle
      tbs-slide-toggle
      (change)="keyAccountManagerChange($event)"
      labelPosition="before"
      [checked]="kamForCompaniesChecked"
    >
      Make it my Key Account Manager:
    </mat-slide-toggle>
  </div>

  <div class="form-control label-left">
    <label class="form-label">Phone number:</label>

    <tbs-input>
      <mat-form-field floatLabel="never">
        <input
          matInput
          formControlName="phone_number"
          placeholder="Enter phone number"
          [readonly]="([permission.userFormEditPhoneNumber] | can | async) === false"
        />
      </mat-form-field>
    </tbs-input>
  </div>

  <div *ngIf="[permission.userFormViewRole] | can | async" class="form-control label-left">
    <label class="form-label">Role:</label>
    <tbs-select tbsSize="default" class="align-select">
      <mat-form-field floatLabel="never">
        <i class="ci-chevron-down-24"></i>
        <mat-select
          formControlName="roles"
          disableOptionCentering
          [disabled]="([permission.userFormEditRole] | can | async) === false || user._id === data._id"
          panelClass="default"
        >
          <mat-option *ngFor="let role of roles" [value]="role.value" class="mat-body-1">
            {{ role.text }}
            <i class="ci-check-24"></i>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </tbs-select>
  </div>

  <div class="form-actions" *ngIf="([permission.userFormSaveProfile] | can | async) && !dialog">
    <button
      tbs-button
      tbsIconPosition="left"
      [disabled]="(userForm.pristine && user.first_login_finished) || submitted"
      (click)="submit()"
    >
      <i class="ci-check-24"></i>{{ !submitted ? 'Save Profile' : 'Profile Saved' }}
    </button>

    <button
      *ngIf="([permission.userFormDisableUser] | can | async) && user._id !== data._id"
      tbs-button
      [tbsType]="data.disabled ? 'solid' : 'text'"
      tbsIconPosition="left"
      (click)="changeEnablement()"
    >
      <i class="ci-x-24"></i>{{ data.disabled ? 'Enable User' : 'Disable User' }}
    </button>
  </div>
</form>

<ngx-spinner
  [name]="data._id"
  type="ball-spin-clockwise"
  size="medium"
  [bdColor]="spinnerFullscreen ? 'rgba(51, 51, 51, 0.8)' : 'rgba(244, 246, 249, 0.8)'"
  [fullScreen]="spinnerFullscreen"
></ngx-spinner>
