import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { RolesService } from '../services/roles.service';

@Pipe({
  name: 'roleOnly',
})
export class RoleOnlyPipe implements PipeTransform {
  constructor(private rolesService: RolesService) {}

  public transform(roles: string[]): Observable<boolean> {
    return this.rolesService.hasRoles(roles);
  }
}
