import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DailyReportData,
  DailyReportScope,
  DailyReportSubscope,
} from '@construction/modules/progress-tracking/types/daily-report.types';
import { PaginatedResponse, Pagination } from '@core/models/project.model';
import moment, { Moment } from 'moment';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  DroneOrthoPcsClasses,
  DroneOrthos,
  EstimateOfDayScope,
  EstimateOfDayScopeCumulative,
  OrthoAcBlock,
  OrthoDate,
  OrthoImage,
  ProjectPreset,
  ScopeLockInfo,
  UpdateAutoDetectionReviewedStatus,
  WbsConfigEdit,
  WbsConfiguration,
  WbsConfigurationData,
  WbsConfigurationScope,
  WbsOriginalPlan,
  WbsOriginalPlanData,
} from '../construction.types';
import {
  ConstructionProjectCreateModel,
  ConstructionProjectFeaturesType,
  ConstructionProjectList,
  ConstructionProjectLoadModel,
  ConstructionProjectSaveModel,
  EmptyPackReturnsResponse,
  NonWorkingDays,
  ProjectWorkhours,
  Sensor,
} from '../models/construction-project.model';
import { ConstructionProjectFeaturesOrderEnum, MapLayerEnum } from '@construction/construction.enums';
import { ConstructionGeoService, Holiday } from './construction-geo.service';
import { TBResponse } from '@construction/models/wbs-elements.model';
import { DailyReportScopeSubScopeStatusEnum } from '@construction/modules/progress-tracking/enums/daily-report.enum';
import {
  AddMilestoneModel,
  IMilestone,
} from '@construction/modules/project-edit/modules/construction-milestones/milestones.model';
import {
  RoverForm,
  Rover,
} from '@construction/modules/project-edit/modules/construction-devices/components/rovers-list/rovers-list-component';
import { SupplyPlanResponseData } from '@construction/modules/project-edit/modules/logistics/views/supply-plan/supply-plan.type';
import { ReferenceLayer, EquipmentSpecs } from '@construction/modules/project-edit/projects.types';
import { EmptyPackReturnsResponseData } from '@construction/modules/project-edit/modules/logistics/views/empty-pack-returns/empty-pack-returns.type';
import { WeekNumberEquivalentProjectWeekType } from '@construction/modules/project-edit/modules/construction-schedule/schedule.types';
import { ConstructionProjectStoreService } from './construction-project-store.service';
import { ProjectService } from '@core/services/common/project.service';
import { ProjectUserContactProfile } from '@core/interfaces/user.interface';
import { SortModelItem } from 'ag-grid-community';
import { ServerSideTableService } from '@construction/shared/rich-table/rich-table/services/server-side-table.service';
import { ContactProfileModel } from '@user/user.component';

export type OrthoParams = {
  page_size: number;
  page: number;
  flight_start_from?: string;
  flight_start_to?: string;
  drone_orthos_id?: string;
};

export type PanoParams = {
  page_size: number;
  page: number;
  date_from?: string;
  date_to?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ConstructionProjectsService {
  public groupWbsLayers = [
    MapLayerEnum.AREA,
    MapLayerEnum.FEEDERS,
    MapLayerEnum.PCS,
    MapLayerEnum.COMBINER_BOXES,
    MapLayerEnum.SITE,
  ];

  private outOfRangeValue = 'Out of range';

  constructor(
    private http: HttpClient,
    private constructionProjectStoreService: ConstructionProjectStoreService,
    private projectService: ProjectService,
    private geoProjectService: ConstructionGeoService,
    private serverSideTableService: ServerSideTableService,
  ) {}

  public getHolidays(projectStartDate: Moment, projectEndDate: Moment): Holiday[] {
    const holidaysInUS: Holiday[] = [
      { name: 'New Years', date: '2023-01-01', country: 'US' },
      { name: 'New Years', date: '2024-01-01', country: 'US' },
      { name: 'New Years', date: '2025-01-01', country: 'US' },
      { name: "New Year's Day (in lieu)", date: '2023-01-02', country: 'US' },
      { name: 'Martin Luther King Jr. Day', date: '2023-01-16', country: 'US' },
      { name: 'Martin Luther King Jr. Day', date: '2024-01-15', country: 'US' },
      { name: 'Martin Luther King Jr. Day', date: '2025-01-20', country: 'US' },
      { name: "President's Day", date: '2023-02-20', country: 'US' },
      { name: "President's Day", date: '2024-02-19', country: 'US' },
      { name: "President's Day", date: '2025-02-17', country: 'US' },
      { name: 'Memorial Day', date: '2023-03-29', country: 'US' },
      { name: 'Memorial Day', date: '2024-03-27', country: 'US' },
      { name: 'Memorial Day', date: '2025-03-26', country: 'US' },
      { name: 'Juneteenth', date: '2023-06-19', country: 'US' },
      { name: 'Juneteenth', date: '2024-06-19', country: 'US' },
      { name: 'Juneteenth', date: '2025-06-19', country: 'US' },
      { name: 'Independence Day', date: '2023-07-04', country: 'US' },
      { name: 'Independence Day', date: '2024-07-04', country: 'US' },
      { name: 'Independence Day', date: '2025-07-04', country: 'US' },
      { name: 'Labor Day', date: '2023-09-04', country: 'US' },
      { name: 'Labor Day', date: '2024-09-02', country: 'US' },
      { name: 'Labor Day', date: '2025-09-01', country: 'US' },
      { name: 'Columbus Day', date: '2023-10-09', country: 'US' },
      { name: 'Columbus Day', date: '2024-10-14', country: 'US' },
      { name: 'Columbus Day', date: '2025-10-13', country: 'US' },
      { name: "Veteran's Day", date: '2023-11-10', country: 'US' },
      { name: "Veteran's Day", date: '2024-11-11', country: 'US' },
      { name: "Veteran's Day", date: '2025-11-11', country: 'US' },
      { name: 'Thanksgiving', date: '2023-11-23', country: 'US' },
      { name: 'Thanksgiving', date: '2024-11-28', country: 'US' },
      { name: 'Thanksgiving', date: '2025-11-27', country: 'US' },
      { name: 'Day After Thanksgiving', date: '2023-11-24', country: 'US' },
      { name: 'Day After Thanksgiving', date: '2024-11-29', country: 'US' },
      { name: 'Day After Thanksgiving', date: '2025-11-28', country: 'US' },
      { name: 'Christmas Day', date: '2023-12-25', country: 'US' },
      { name: 'Christmas Day', date: '2024-12-25', country: 'US' },
      { name: 'Christmas Day', date: '2025-12-25', country: 'US' },
    ];

    return holidaysInUS.filter((holiday) => {
      const holidayDate = moment(holiday.date);

      return holidayDate.isBetween(projectStartDate, projectEndDate, 'day', '[]');
    });
  }

  /**
   * Function that groups calendar work weeks by year, in the range that we provided,
   * with their corresponding project work week
   *@param startDate start of our date range
   *@param endDate end of our date range
   *@returns Grouped project work weeks by year
   */
  public mappedProjectWeeksGrouped(
    startDate: Moment,
    endDate: Moment,
  ): Map<number, WeekNumberEquivalentProjectWeekType> {
    //get a starting year
    let year = startDate.clone().year();
    //starting project week
    let projectWeek = 1;
    //calendar work week for keeping track
    let isoWeekNumber = 0;

    let data: Record<number, number> = {};
    //if the week is continuing to next year
    let carryOver = false;

    //a map of a grouped project weeks
    const projectWeekNumber: Map<number, WeekNumberEquivalentProjectWeekType> = new Map();

    while (startDate.isSameOrBefore(endDate)) {
      const weekNumber = startDate.clone().isoWeek();
      const dateYear = startDate.clone().year();

      //check if it's new week in the same year
      if (weekNumber !== isoWeekNumber && year === dateYear) {
        //if we didn't already added this week to this year
        if (!data[weekNumber]) {
          data[weekNumber] = projectWeek;
        } else {
          //if we did, this means that this week is in the next year
          //NOTE: only happens for the first week of the year
          carryOver = true;
        }
        //for keeping track that we are not finished with this week
        isoWeekNumber = weekNumber;
        projectWeek++;
      }
      if (year !== dateYear) {
        //if we got to the next year, set entry of the last in the map
        projectWeekNumber.set(year, data);
        //increase year and reset the data for next year
        year++;
        data = {};
        //if the week continued in the next year
        if (carryOver) {
          //apply the first week of the next year
          data[weekNumber] = projectWeek - 1;
          //reset the flag
          carryOver = false;
        }
      }
      startDate.add(1, 'day');
    }
    //when we get out of the while condition, apply the last year that we mapped
    projectWeekNumber.set(year, data);

    return projectWeekNumber;
  }

  public createConstructionProject(
    data: ConstructionProjectCreateModel | FormData,
  ): Observable<ConstructionProjectLoadModel> {
    return this.http.post(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/`, data).pipe(
      map((projects: any) => {
        return projects.data;
      }),
    );
  }

  public getConstructionProjects(
    page = 1,
    page_size = 15,
    sort_by = 'project_name',
    sort_order = '',
    project_name_regex = '',
    with_last_drone_ortho_dt = false,
  ) {
    return this.http.get<{ data: ConstructionProjectList[]; pagination: Pagination }>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/`,
      {
        params: {
          page,
          page_size,
          sort_by,
          sort_order,
          project_name_regex,
          with_last_drone_ortho_dt,
        },
      },
    );
  }

  public getConstructionProject(projectId: string, withWbsConfig = false): Observable<ConstructionProjectLoadModel> {
    return this.http
      .get<{
        data: ConstructionProjectLoadModel;
      }>(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/`, {
        params: { with_wbs_config: withWbsConfig, with_daily_digest_contact_profiles: true, with_procore_integration: true, with_project_workhours: true },
      })
      .pipe(map((response) => response.data));
  }

  public getConstructionProjectAndUpdateStore(constructionProjectId: string): Observable<ConstructionProjectLoadModel> {
    return this.getConstructionProject(constructionProjectId, true).pipe(
      switchMap((constructionProject) => {
        const project$ = this.projectService.getSingleProject(constructionProject.project._id);
        const geoProject$ = constructionProject.geo_project_uuid
          ? this.geoProjectService.getProjectData(constructionProject.geo_project_uuid)
          : of(undefined);
        const presetDates$ = this.getPresetDates(constructionProject._id);

        return forkJoin([project$, geoProject$, presetDates$]).pipe(
          map(([projectRes, geoProject, presetDates]) => {
            constructionProject.project = projectRes.data;
            constructionProject.geoProject = geoProject;
            constructionProject.presetData = presetDates;

            return constructionProject;
          }),
        );
      }),
      tap((response) => {
        console.log('UPDATING CONSTRUCTION PROJECT STORE');
        this.constructionProjectStoreService.updateConstructionProjectStore(response);
      }),
    );
  }

  public updateConstructionProject(
    projectId: string,
    data: ConstructionProjectSaveModel | undefined,
  ): Observable<ConstructionProjectLoadModel> {
    return this.http
      .patch(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/`, {
        ...data,
      })
      .pipe(switchMap(() => this.getConstructionProjectAndUpdateStore(projectId)));
  }

  public updateNonWorkingDays(projectId: string, nonWorkingDays?: NonWorkingDays, projectWorkHours?: ProjectWorkhours) {
    const data = {
      non_working_days: nonWorkingDays,
      project_workhours: projectWorkHours,
    };

    return this.http
      .patch(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/non_working_days/`,
        data,
      )
      .pipe(switchMap(() => this.getConstructionProjectAndUpdateStore(projectId)));
  }

  public submitConstructionProject(projectId: string): Observable<ConstructionProjectLoadModel> {
    return this.http
      .post(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/submit/`, {})
      .pipe(switchMap(() => this.getConstructionProjectAndUpdateStore(projectId)));
  }

  public verifyConstructionProject(projectId: string, geoProjectUuid: string) {
    return this.http
      .post(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/verify/${geoProjectUuid}/`,
        {},
      )
      .pipe(switchMap(() => this.getConstructionProjectAndUpdateStore(projectId)));
  }

  public addConstructionProjectDocument(
    projectId: string,
    data: FormData,
  ): Observable<{ data: ConstructionProjectLoadModel }> {
    return this.http.post<{ data: ConstructionProjectLoadModel }>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/documents/`,
      data,
    );
  }

  public deleteConstructionProjectDocument(
    projectId: string,
    fileId: string,
  ): Observable<{ data: ConstructionProjectLoadModel }> {
    return this.http.delete<{ data: ConstructionProjectLoadModel }>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/documents/${fileId}/`,
    );
  }

  public getDroneOrthos(
    construction_project_pk: string,
    querryParams: OrthoParams,
    sortModel: SortModelItem[],
    autoDetection = false,
  ): Observable<DroneOrthos> {
    let params = new HttpParams();

    params = params.append('page', querryParams.page);
    params = params.append('page_size', querryParams.page_size);

    if (sortModel.length) {
      const fieldName = this.serverSideTableService.changeSortColumnsName(sortModel[0]);
      params = params.append('sort_by', fieldName);
      params = params.append('sort_order', sortModel[0].sort.toUpperCase());
    }

    if (querryParams.flight_start_from) {
      params = params.append('flight_start_from', querryParams.flight_start_from);
    }
    if (querryParams.flight_start_to) {
      params = params.append('flight_start_to', querryParams.flight_start_to);
    }

    return this.http.get<DroneOrthos>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${construction_project_pk}/drone_orthos/${
        querryParams.drone_orthos_id ? querryParams.drone_orthos_id + '/' : ''
      }?auto_detect=${autoDetection}`,
      {
        params: params,
      },
    );
  }

  public getDroneOrtho(construction_project_pk: string, drone_orthos_id?: string): Observable<OrthoImage> {
    return this.http
      .get<{
        data: OrthoImage;
      }>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${construction_project_pk}/drone_orthos/${drone_orthos_id + '/'}`,
      )
      .pipe(
        map((drone_orthos) => {
          return drone_orthos.data;
        }),
      );
  }

  public uploadDroneOrthos(projectId: string, data?: OrthoImage): Observable<ConstructionProjectLoadModel> {
    return this.http
      .post(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/drone_orthos/`, data)
      .pipe(
        map((drone_orthos: any) => {
          return drone_orthos.data;
        }),
      );
  }

  public deleteDroneOrtho(projectId: string, droneFlightId: string): Observable<unknown> {
    return this.http.delete(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/drone_orthos/${droneFlightId}/`,
    );
  }

  public updateDroneOrthos(
    projectId: string,
    droneFlightId: string,
    data?: OrthoImage,
  ): Observable<ConstructionProjectLoadModel> {
    return this.http
      .patch(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/drone_orthos/${droneFlightId}/`,
        data,
      )
      .pipe(
        map((drone_orthos: any) => {
          return drone_orthos.data;
        }),
      );
  }

  public getOrthoListDates(
    projectId: string,
    autoDetection = false,
    areaId = '',
    latestOnly = false,
    dateFrom = '',
    dateTo = '',
  ): Observable<{ data: OrthoDate[] }> {
    let params = new HttpParams();
    params = params.append('auto_detect', autoDetection);
    params = params.append('area_id', areaId);
    params = params.append('page_size', 1000);
    params = params.append('latest_only', latestOnly);
    params = params.append('flight_start_from', dateFrom);
    params = params.append('flight_start_to', dateTo);

    return this.http.get<{ data: OrthoDate[] }>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/list_drone_ortho_dates/`,
      { params },
    );
  }

  public updateAcBlockReviewedStatus(
    projectId: string,
    orthoId: string,
    data: UpdateAutoDetectionReviewedStatus[],
  ): Observable<{ data: OrthoAcBlock[] }> {
    return this.http.patch<{ data: OrthoAcBlock[] }>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/drone_orthos/${orthoId}/pcs_ids`,
      data,
    );
  }

  public addDronePano(projectId: string, data: FormData): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/panos/`, data)
      .pipe(
        map((pano: any) => {
          return pano.data;
        }),
      );
  }

  public getDronePanos(projectId: string, querryParams: PanoParams, sortModel: SortModelItem[]): Observable<any> {
    let params = new HttpParams();

    params = params.append('page', querryParams.page);
    params = params.append('page_size', querryParams.page_size);

    if (sortModel.length) {
      const fieldName = this.serverSideTableService.changeSortColumnsName(sortModel[0]);
      params = params.append('sort_by', fieldName);
      params = params.append('sort_order', sortModel[0].sort.toUpperCase());
    }

    if (querryParams.date_from) {
      params = params.append('date_from', querryParams.date_from);
    }
    if (querryParams.date_to) {
      params = params.append('date_to', querryParams.date_to);
    }

    return this.http.get(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/panos/`, {
      params,
    });
  }

  public getDronePano(projectId: string, panoId: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/panos/${panoId}/`)
      .pipe(
        map((pano: any) => {
          return pano.data;
        }),
      );
  }

  public deleteDronePano(projectId: string, panoId: string): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/panos/${panoId}/`,
    );
  }

  public updateDronePano(projectId: string, panoId: string, data: any): Observable<any> {
    return this.http
      .patch(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/panos/${panoId}/`,
        data,
      )
      .pipe(
        map((pano: any) => {
          return pano.data;
        }),
      );
  }

  public getPanoListDates(projectId: string): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/list_pano_dates/`, {
        params: { page_size: 1000 },
      })
      .pipe(
        map((pano: any) => {
          return pano.data;
        }),
      );
  }

  public patchSensors(constructionProjectId: string, sensors: Sensor[]): Observable<Sensor[]> {
    return this.http
      .patch<
        TBResponse<Sensor[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${constructionProjectId}/sensors/`, { sensors })
      .pipe(map((response) => response.data));
  }

  /**
   *
   * @param constructionProjectId construction project id
   * @param data new rover data
   * @returns Observable of the construction project
   */
  public addRover(constructionProjectId: string, data: RoverForm): Observable<any> {
    return this.http
      .post(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${constructionProjectId}/rovers/`,
        data,
      )
      .pipe(switchMap(() => this.getConstructionProjectAndUpdateStore(constructionProjectId)));
  }

  /**
   *
   * @param constructionProjectId construction project id
   * @param rover_id id of the rover that we want to delete
   * @returns Observable of the construction project
   */
  public deleteRover(constructionProjectId: string, rover_id: string): Observable<any> {
    return this.http
      .delete(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${constructionProjectId}/rovers/${rover_id}/`,
      )
      .pipe(switchMap(() => this.getConstructionProjectAndUpdateStore(constructionProjectId)));
  }

  /**
   *
   * @param constructionProjectId construction project id
   * @param data new rover data
   * @returns Observable of the construction project
   */
  public roverToggle(constructionProjectId: string, data: Rover): Observable<any> {
    const roverData = { ...data } as Partial<Rover>;
    delete roverData.uuid;

    return this.http
      .patch(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${constructionProjectId}/rovers/${data.uuid}/`,
        roverData,
      )
      .pipe(switchMap(() => this.getConstructionProjectAndUpdateStore(constructionProjectId)));
  }

  public addMilestone(projectId: string | undefined, milestone: AddMilestoneModel): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/milestones/`,
      milestone,
    );
  }

  public editMilestone(projectId: string | undefined, milestone: AddMilestoneModel): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/milestones/${milestone._id}/`,
      milestone,
    );
  }

  public getMilestones(projectId: string, elementType?: string): Observable<IMilestone[]> {
    let params: any = { page_size: 1000 };

    if (elementType) {
      params = { ...params, element_type: elementType };
    }

    return this.http
      .get(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/milestones/`, {
        params: params,
      })
      .pipe(
        map((milestones: any) => {
          return milestones.data;
        }),
      );
  }

  public deleteMilestone(projectId: string, milestoneId: string): Observable<unknown> {
    return this.http.delete(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/milestones/${milestoneId}/`,
    );
  }

  public getReferenceLayers(projectId: string): Observable<{ data: ReferenceLayer[] }> {
    return this.http.get<{ data: ReferenceLayer[] }>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/reference_layers/?page_size=100`,
    );
  }

  public createReferenceLayer(projectId: string, data: ReferenceLayer): Observable<{ data: ReferenceLayer[] }> {
    return this.http
      .post<{
        data: ReferenceLayer[];
      }>(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/reference_layers/`, data)
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result))));
  }

  public editReferenceLayer(
    projectId: string,
    layerId: string,
    data: ReferenceLayer,
  ): Observable<{ data: ReferenceLayer[] }> {
    return this.http
      .patch<{
        data: ReferenceLayer[];
      }>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/reference_layers/${layerId}/`,
        data,
      )
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result))));
  }

  public deleteReferenceLayer(projectId: string, layerId: string): Observable<{ data: ReferenceLayer[] }> {
    return this.http
      .delete<{
        data: ReferenceLayer[];
      }>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/reference_layers/${layerId}/`,
      )
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result))));
  }

  public getSupplyPlanWorkWeeks(projectId: string) {
    return this.http.get<TBResponse<SupplyPlanResponseData>>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/supply_plan/`,
    );
  }

  public saveSupplyPlan(projectId: string, data: SupplyPlanResponseData) {
    return this.http
      .post<
        TBResponse<SupplyPlanResponseData>
      >(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/supply_plan/`, data)
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result))));
  }

  public getEquipmentSpecs(projectId: string) {
    return this.http
      .get<{
        data: EquipmentSpecs;
      }>(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/equipment_specs/`)
      .pipe(
        map((equipment) => {
          return equipment.data;
        }),
      );
  }

  public saveEquipmentSpecs(projectId: string, data: EquipmentSpecs) {
    return this.http
      .post<EquipmentSpecs>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/equipment_specs/`,
        data,
      )
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result))));
  }

  public getEmptyPacksWorkWeeks(projectId: string): Observable<EmptyPackReturnsResponse> {
    return this.http.get<EmptyPackReturnsResponse>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/empty_packs/`,
    );
  }

  public saveEmptyPacks(projectId: string, data: EmptyPackReturnsResponseData): Observable<EmptyPackReturnsResponse> {
    return this.http
      .post<EmptyPackReturnsResponse>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/empty_packs/`,
        data,
      )
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result))));
  }

  public getPCSClasses(projectId: string, droneOrthoId: string): Observable<DroneOrthoPcsClasses[]> {
    return this.http
      .get<{
        data: DroneOrthoPcsClasses[];
      }>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/drone_orthos/${droneOrthoId}/all_class_counters_per_pcs/`,
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  public getPresetDates(projectId: string): Observable<ProjectPreset> {
    return this.http
      .get<{
        data: ProjectPreset;
      }>(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/presets/`)
      .pipe(map((res) => res.data));
  }

  public saveWbsConfiguration(projectId: string, data: WbsConfigurationScope[]): Observable<WbsConfiguration> {
    return this.http
      .post<{ data: WbsConfiguration }>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/omega/wbs_config/`,
        {
          scopes: data,
        },
      )
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result.data))));
  }

  public publishWbsConfiguration(projectId: string): Observable<WbsConfigurationData> {
    return this.http
      .put<WbsConfigurationData>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/omega/wbs_config/publish/`,
        null,
      )
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result))));
  }

  public updateWbsConfigurationPublished(projectId: string, data: WbsConfigEdit): Observable<WbsConfigurationData> {
    const copy: any = { ...data };
    copy.deleted_activities = copy.deleted_activities.map((uuid: string) => {
      return { activity_uuid: uuid };
    });

    return this.http
      .put<WbsConfigurationData>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/omega/wbs_config/`,
        copy,
      )
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result))));
  }

  public getEod(date_from: string, date_to: string, scope: string, idProject: string): Observable<EstimateOfDayScope> {
    let params = new HttpParams();
    params = params.append('date_from', date_from);
    params = params.append('date_to', date_to);
    params = params.append('scope', scope);

    return this.http
      .get<{ data: EstimateOfDayScope }>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${idProject}/omega/estimate_of_day/`,
        {
          params,
        },
      )
      .pipe(
        map((projects) => {
          return projects.data;
        }),
      );
  }

  public getEodCumulative(date: string, scope: string, idProject: string): Observable<EstimateOfDayScopeCumulative> {
    let params = new HttpParams();
    params = params.append('date', date);

    return this.http
      .get<{ data: EstimateOfDayScopeCumulative }>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${idProject}/omega/estimate_of_day/${scope}/cumulative/`,
        {
          params,
        },
      )
      .pipe(
        map((projects) => {
          return projects.data;
        }),
      );
  }

  public saveEod(projectId: string, data: EstimateOfDayScope): Observable<EstimateOfDayScope> {
    return this.http.post<EstimateOfDayScope>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/omega/estimate_of_day/`,
      data,
    );
  }

  public lockScope(projectId: string, scope: string): Observable<ScopeLockInfo> {
    return this.http
      .post<{
        data: ScopeLockInfo;
      }>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/omega/scope_lock/${scope}/`,
        undefined,
      )
      .pipe(switchMap((result) => this.getConstructionProjectAndUpdateStore(projectId).pipe(map(() => result.data))));
  }

  public cancelScope(projectId: string, scope: string): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/omega/scope_lock/${scope}/`,
      undefined,
    );
  }

  public getLockScope(projectId: string, scopeId: string): Observable<ScopeLockInfo> {
    return this.http
      .get<{
        data: ScopeLockInfo;
      }>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/omega/scope_lock/${scopeId}/`,
        undefined,
      )
      .pipe(
        map((lockInfo) => {
          return lockInfo.data;
        }),
      );
  }

  public getDailyReport(
    idProject: string,
    date: string,
    trendTimeFrame: number,
    forecastTimeFrame: number,
    timezone: string,
  ) {
    let params = new HttpParams();
    params = params.append('date', date);
    params = params.append('trend_period', trendTimeFrame);
    params = params.append('forecast_time_frame', forecastTimeFrame);

    return this.http
      .get<{
        data: DailyReportData;
      }>(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${idProject}/omega/daily_report/`, {
        params,
      })
      .pipe(
        map((response) => {
          return this.calculateTotals(response.data, date, timezone);
        }),
      );
  }

  public getWbsConfig(idProject: string): Observable<WbsConfiguration> {
    return this.http
      .get<WbsConfigurationData>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${idProject}/omega/wbs_config/`,
      )
      .pipe(map((response) => response.data));
  }

  public verifyProjectInService(constructionProjectId: string): Observable<void> {
    return this.http
      .post<void>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${constructionProjectId}/verify_in_service/`,
        undefined,
      )
      .pipe(
        switchMap((result) => this.getConstructionProjectAndUpdateStore(constructionProjectId).pipe(map(() => result))),
      );
  }

  public updateDailyDigest(
    projectId: string,
    data: ConstructionProjectSaveModel | undefined,
  ): Observable<ConstructionProjectLoadModel> {
    return this.http
      .patch<TBResponse<ConstructionProjectLoadModel>>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/`,
        {
          ...data,
          trigger_generate_schedule: false,
        },
      )
      .pipe(map((res) => res.data));
  }

  public generateProjectDataPackage(projectId: string): Observable<ConstructionProjectLoadModel> {
    return this.http
      .post<
        TBResponse<ConstructionProjectLoadModel>
      >(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${projectId}/package/`, {})
      .pipe(map((res) => res.data));
  }

  public getContactProfileProjectUsers(
    constructionProjectId: string,
    contactProfiles: string[],
  ): Observable<ProjectUserContactProfile[]> {
    return this.http
      .get<
        TBResponse<ProjectUserContactProfile[]>
      >(`${environment.apiUrl}/${environment.apiVersion}/construction_projects/${constructionProjectId}/contact_profiles/?contact_profile_ids=${contactProfiles}`)
      .pipe(map((response) => response.data));
  }

  public getContactProfiles(
    constructionProjectId: string,
    companyIds: string[] ,
    usedProfiles: string[] ,
    searchQuery: string,
    page: number,
    pageSize = 10,
  ): Observable<PaginatedResponse<ContactProfileModel>> {
    const params = new HttpParams({ fromObject: { page, page_size: pageSize } });

    return this.http.post<PaginatedResponse<ContactProfileModel>>(
      `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${constructionProjectId}/all/companies/users/`,
      {
        company_ids: companyIds,
        name: searchQuery || undefined,
        already_used_profiles: usedProfiles || undefined,
        disabled: false,
      },
      { params },
    );
  }

  public getOriginalPlan(
    idProject: string,
    dateFrom: string,
    dateTo: string,
    idScope: string,
  ): Observable<WbsOriginalPlanData> {
    let params = new HttpParams();
    params = params.append('date_from', dateFrom);
    params = params.append('date_to', dateTo);
    params = params.append('scope', idScope);

    return this.http
      .get<WbsOriginalPlan>(
        `${environment.apiUrl}/${environment.apiVersion}/construction_projects/${idProject}/omega/plan/`,
        {
          params,
        },
      )
      .pipe(map((response) => response.data));
  }

  public mapProjectFeatures(features: ConstructionProjectFeaturesType[]) {
    const projectFeatures = features ? features : listOfFeatures;

    return projectFeatures
      .map((feature) => {
        return {
          name: feature.name,
          owner_enabled: feature.owner_enabled || false,
          epc_enabled: feature.epc_enabled || false,
          supplier_enabled: feature.supplier_enabled || false,
          aerial_data: feature.aerial_data,
          ground_data: feature.ground_data,
          id: +ConstructionProjectFeaturesOrderEnum[feature.name as never],
        };
      })
      .sort((a, b) => a.id - b.id);
  }

  private calculateTotals(reportData: DailyReportData, selectedDate: string, timezone: string) {
    let actualProgress = 0;
    let plannedProgress = 0;
    let recentTrend = 0;
    let previousTrend = 0;
    reportData.scopes.forEach((scope: DailyReportScope) => {
      let scopeWeight = 0;
      let scopeTotalPercentage = 0;
      let scopePlannedPercentage = 0;
      let scopeRecentActual = 0;
      let scopePreviousActual = 0;
      let latestScopeOriginalPlan = '';
      let latestScopeForecast = '';
      scope.sub_scopes.forEach((subscope: DailyReportSubscope) => {
        const subScopeTotalWeight = subscope.activities.reduce((cum, acc) => cum + acc.weight, 0);
        let subScopeTotalPercentage = 0;
        let subScopePlannedPercentage = 0;
        let subScopeRecentActual = 0;
        let subScopePreviousActual = 0;
        let activityRecentActualPercentage = 0;
        let activityPreviousActualPercentage = 0;
        let latestSubScopeOriginalPlan = '';
        let latestSubScopeForecast = '';
        let atLeastOneOutOfRange = false;
        const forecastDates: string[] = [];
        let notStartedCounter = 0;
        let completedCounter = 0;
        subscope.activities.forEach((activity) => {
          activity.percentage =
            activity.eac_quantity && activity.eac_quantity > 0
              ? ((activity.cumulative_quantity || 0) / activity.eac_quantity) * 100
              : 0;
          activity.plannedPercentage =
            activity.total_planned && activity.total_planned > 0
              ? (activity.total_planned / activity.eac_quantity) * 100
              : 0;

          activityRecentActualPercentage = (activity.recent_actual / activity.eac_quantity) * 100;
          activityPreviousActualPercentage = (activity.previous_actual / activity.eac_quantity) * 100;

          const originalPlan = activity.end_date;
          const activityForecastDate = activity.forecast_date;
          const isActivityStarted = moment(activity.start_date)
            .tz(timezone, false)
            .isSameOrBefore(moment(selectedDate).tz(timezone, false), 'date');
          const isActivityCompleted = activity.percentage >= 100;

          //For original completion date
          if (!latestSubScopeOriginalPlan) latestSubScopeOriginalPlan = originalPlan;

          if (
            latestSubScopeOriginalPlan &&
            moment(originalPlan)
              .tz(timezone, false)
              .isAfter(moment(latestSubScopeOriginalPlan).tz(timezone, false), 'date')
          ) {
            latestSubScopeOriginalPlan = originalPlan;
          }

          //For latest forecast date
          if (!activityForecastDate && !isActivityCompleted && isActivityStarted) {
            atLeastOneOutOfRange = true;
          }

          if (!isActivityStarted) notStartedCounter++;

          if (isActivityCompleted) completedCounter++;

          if (isActivityStarted && activityForecastDate) {
            forecastDates.push(activityForecastDate);
          }

          if (subScopeTotalWeight > 0) {
            subScopeTotalPercentage += (activity.weight / subScopeTotalWeight) * activity.percentage;
            subScopePlannedPercentage += (activity.weight / subScopeTotalWeight) * activity.plannedPercentage;
            subScopeRecentActual += (activity.weight / subScopeTotalWeight) * activityRecentActualPercentage;
            subScopePreviousActual += (activity.weight / subScopeTotalWeight) * activityPreviousActualPercentage;
          }
        });

        latestSubScopeForecast = this.getSubScopeForecastDate(
          atLeastOneOutOfRange,
          subscope,
          notStartedCounter,
          completedCounter,
          forecastDates,
          timezone,
        );

        subscope.subScopePercentageComplete = subScopeTotalPercentage;
        subscope.subScopeTotalWeight = subScopeTotalWeight;
        subscope.subScopePlannedPercentage = subScopePlannedPercentage;
        subscope.subScopeRecentActual = subScopeRecentActual;
        subscope.subScopePreviousActual = subScopePreviousActual;
        subscope.latestOriginalPlan = latestSubScopeOriginalPlan;
        subscope.latestForecastDate = latestSubScopeForecast;
        scopeWeight += subScopeTotalWeight;
      });
      scope.sub_scopes.forEach((subscope: DailyReportSubscope) => {
        const originalPlan = subscope.latestOriginalPlan;
        const forecastDate = subscope.latestForecastDate;

        if (!latestScopeOriginalPlan) latestScopeOriginalPlan = originalPlan;
        if (!latestScopeForecast) {
          if (forecastDate === this.outOfRangeValue) {
            latestScopeForecast = this.outOfRangeValue;
          } else {
            latestScopeForecast = forecastDate ? forecastDate : '';
          }
        }

        if (
          latestScopeOriginalPlan &&
          moment(originalPlan).tz(timezone, false).isAfter(moment(latestScopeOriginalPlan).tz(timezone, false), 'date')
        ) {
          latestScopeOriginalPlan = originalPlan;
        }

        if (
          latestScopeForecast !== this.outOfRangeValue &&
          moment(forecastDate).tz(timezone, false).isAfter(moment(latestScopeForecast).tz(timezone, false), 'date')
        ) {
          latestScopeForecast = forecastDate;
        }

        if (subscope.subScopeTotalWeight && subscope.subScopeTotalWeight > 0) {
          scopeTotalPercentage += (subscope.subScopeTotalWeight / scopeWeight) * subscope.subScopePercentageComplete;
          scopePlannedPercentage += (subscope.subScopeTotalWeight / scopeWeight) * subscope.subScopePlannedPercentage;
          scopeRecentActual += (subscope.subScopeTotalWeight / scopeWeight) * subscope.subScopeRecentActual;
          scopePreviousActual += (subscope.subScopeTotalWeight / scopeWeight) * subscope.subScopePreviousActual;
        }

        const subScopeStatus = this.getSubScopeStatus(subscope, selectedDate, timezone);

        subscope.status = subScopeStatus;
      });

      scope.scopeTotalWeight = scopeWeight;
      scope.scopePercentageComplete = scopeTotalPercentage;
      scope.scopePlannedPercentage = scopePlannedPercentage;
      scope.scopeRecentActual = scopeRecentActual;
      scope.scopePreviousActual = scopePreviousActual;
      scope.originalCompletionDate = latestScopeOriginalPlan;
      scope.forecastDate = latestScopeForecast;
      actualProgress += ((scopeWeight || 0) / 100) * (scopeTotalPercentage || 0);
      plannedProgress += ((scopeWeight || 0) / 100) * (scopePlannedPercentage || 0);

      recentTrend += ((scopeWeight || 0) / 100) * (scopeRecentActual || 0);
      previousTrend += ((scopeWeight || 0) / 100) * (scopePreviousActual || 0);
      const status = this.getScopeStatus(scope);
      scope.status = status;
    });

    return { actualProgress, plannedProgress, recentTrend, previousTrend, reportData };
  }

  private getSubScopeForecastDate(
    atLeastOneOutOfRange: boolean,
    subscope: DailyReportSubscope,
    notStartedCounter: number,
    completedCounter: number,
    forecastDates: string[],
    timezone: string,
  ): string {
    if (atLeastOneOutOfRange) return this.outOfRangeValue;
    if (subscope.activities.length === (notStartedCounter || completedCounter)) return '';
    if (forecastDates.length) {
      return forecastDates.reduce((a, b) => {
        return moment(a).tz(timezone, false).isBefore(moment(b).tz(timezone, false)) ? b : a;
      });
    }

    return '';
  }

  private getSubScopeStatus(subscope: DailyReportSubscope, selectedDate: string, timezone: string): number {
    let completedActivities = 0;
    let notStartedActivities = 0;

    subscope.activities.forEach((activity) => {
      if (activity.cumulative_quantity >= activity.eac_quantity) {
        completedActivities++;
      }
      if (
        moment(activity.start_date).tz(timezone, false).isAfter(moment(selectedDate).tz(timezone, false), 'date') &&
        activity.cumulative_quantity === 0
      ) {
        notStartedActivities++;
      }
    });

    if (completedActivities === subscope.activities.length) return DailyReportScopeSubScopeStatusEnum.COMPLETED;
    if (notStartedActivities === subscope.activities.length) return DailyReportScopeSubScopeStatusEnum.NOT_STARTED;
    if (subscope.subScopePercentageComplete > subscope.subScopePlannedPercentage)
      return DailyReportScopeSubScopeStatusEnum.AHEAD;
    if (subscope.subScopePercentageComplete === subscope.subScopePlannedPercentage)
      return DailyReportScopeSubScopeStatusEnum.ON_SCHEDULE;
    if (subscope.subScopePercentageComplete < subscope.subScopePlannedPercentage)
      return DailyReportScopeSubScopeStatusEnum.BEHIND;

    return DailyReportScopeSubScopeStatusEnum.NOT_STARTED;
  }

  private getScopeStatus(scope: DailyReportScope): number {
    let completedSubScopes = 0;
    let notStartedSubScopes = 0;

    scope.sub_scopes.forEach((subscope) => {
      if (subscope.status === DailyReportScopeSubScopeStatusEnum.COMPLETED) {
        completedSubScopes++;
      }
      if (subscope.status === DailyReportScopeSubScopeStatusEnum.NOT_STARTED) {
        notStartedSubScopes++;
      }
    });

    if (completedSubScopes === scope.sub_scopes.length) return DailyReportScopeSubScopeStatusEnum.COMPLETED;
    if (notStartedSubScopes === scope.sub_scopes.length) return DailyReportScopeSubScopeStatusEnum.NOT_STARTED;
    if (scope.scopePercentageComplete > scope.scopePlannedPercentage) return DailyReportScopeSubScopeStatusEnum.AHEAD;
    if (scope.scopePercentageComplete === scope.scopePlannedPercentage)
      return DailyReportScopeSubScopeStatusEnum.ON_SCHEDULE;
    if (scope.scopePercentageComplete < scope.scopePlannedPercentage) return DailyReportScopeSubScopeStatusEnum.BEHIND;

    return DailyReportScopeSubScopeStatusEnum.NOT_STARTED;
  }
}

export const listOfFeatures = [
  {
    name: 'logistics_inventory',
    owner_enabled: true,
    epc_enabled: false,
    supplier_enabled: true,
    id: 0,
    aerial_data: null,
    ground_data: null,
  },
  {
    name: 'qc_issues',
    owner_enabled: true,
    epc_enabled: true,
    supplier_enabled: false,
    id: 1,
    aerial_data: null,
    ground_data: null,
  },
  {
    name: 'digital_twin',
    owner_enabled: true,
    epc_enabled: true,
    supplier_enabled: true,
    id: 2,
    aerial_data: {
      epc_enabled: false,
      name: 'aerial_data',
      owner_enabled: true,
      supplier_enabled: false,
    },
    ground_data: { epc_enabled: true, name: 'ground_data', owner_enabled: false, supplier_enabled: false },
  },
  {
    name: 'production_tracking',
    owner_enabled: false,
    epc_enabled: false,
    supplier_enabled: false,
    id: 3,
    aerial_data: null,
    ground_data: null,
  },
  {
    name: 'equipment_tracking',
    owner_enabled: false,
    epc_enabled: false,
    supplier_enabled: false,
    id: 4,
    aerial_data: null,
    ground_data: null,
  },
  {
    name: 'approvals',
    owner_enabled: true,
    epc_enabled: false,
    supplier_enabled: false,
    id: 5,
    aerial_data: null,
    ground_data: null,
  },
  {
    name: 'omega',
    owner_enabled: true,
    epc_enabled: false,
    supplier_enabled: false,
    id: 6,
    aerial_data: null,
    ground_data: null,
  },
  {
    name: 'mv_circuit',
    owner_enabled: true,
    epc_enabled: true,
    supplier_enabled: false,
    id: 7,
    aerial_data: null,
    ground_data: null,
  },
  {
    name: 'advanced_detection',
    owner_enabled: true,
    epc_enabled: true,
    supplier_enabled: false,
    id: 8,
    aerial_data: null,
    ground_data: null,
  },
  {
    name: 'terafab',
    owner_enabled: false,
    epc_enabled: true,
    supplier_enabled: false,
    id: 9,
    aerial_data: null,
    ground_data: null,
  },
];
