import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProjectRoles } from '../rest/view-context.service';
import { ConstructionProjectStoreService } from '@construction/services/construction-project-store.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectRolesService {
  constructor(private constructionProjectStoreService: ConstructionProjectStoreService) {}

  public getUserProjectRole(): Observable<ProjectRoles> {
    if (
      this.constructionProjectStoreService.hasProjectRole('EPCQualityManagerRemediate') ||
      this.constructionProjectStoreService.hasProjectRole('EPCQualityManagerCreateRemediate')
    ) {
      return of(ProjectRoles.Epc);
    }
    if (this.constructionProjectStoreService.hasProjectRole('OwnerConstructionManager')) {
      return of(ProjectRoles.Owner);
    }
    if (this.constructionProjectStoreService.hasProjectRole('SupplierCompanyUser')) {
      return of(ProjectRoles.Supplier);
    }

    return of(ProjectRoles.Epc);
  }
}
