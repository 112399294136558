

export enum ProgressBarColorsEnum {
  POSITIVE_DELTA = 'green',
  NEGATIVE_DELTA = 'red',
  NEUTRAL_DELTA = 'black',
}

export enum DailyReportStatus {
  COMPLETED = 'Completed',
  AHEAD = 'Ahead',
  ON_SCHEDULE = 'On Schedule',
  BEHIND = 'Behind',
  NOT_STARTED = 'Not Started',
}

export enum DailyReportScopeSubScopeStatusEnum {
  COMPLETED,
  AHEAD,
  ON_SCHEDULE,
  BEHIND,
  NOT_STARTED,
}
