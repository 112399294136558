import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarWrapperComponent } from './components/sidebar/wrapper/sidebar-wrapper.component';
import { SidebarSubnavComponent } from './components/sidebar/nav/subnav/subnav.component';
import { NavItemsContainerComponent } from './components/sidebar/containers/nav-items-container/nav-items-container.component';
import { NavItemsGroupContainerComponent } from './components/sidebar/containers/nav-items-group-container/nav-items-group-container.component';
import { NavItemsGroupComponent } from './components/sidebar/nav/nav-group/nav-items-group.component';
import { NavItemComponent } from './components/sidebar/nav/nav-item/nav-item.component';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { UserProfileDialogComponent } from './components/user-profile-dialog/user-profile-dialog.component';
import { CoreImageModule } from '../shared/core-image/core-image.module';
import { CoreUserFormModule } from '../shared/core-user-form/core-user-form.module';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TbsDialogModule } from '@ngx-terabase/ui/dialog';
import { TbsButtonModule } from '@ngx-terabase/ui/button';
import { ThrottleClickModule } from '@shared/throttle-click/throttle-click.module';

const MATERIAL_MODULES = [MatSidenavModule, MatDialogModule, MatAutocompleteModule, MatDatepickerModule];
const CORE_MODULES = [CoreImageModule, CoreUserFormModule];
const TERABASE_MODULES = [TbsDialogModule, TbsButtonModule];

@NgModule({
  declarations: [
    SidebarComponent,
    MainLayoutComponent,
    SidebarWrapperComponent,
    SidebarSubnavComponent,
    NavItemsContainerComponent,
    NavItemComponent,
    NavItemsGroupComponent,
    NavItemsGroupContainerComponent,
    UserProfileDialogComponent,
  ],
  imports: [CommonModule, RouterModule, MATERIAL_MODULES, CORE_MODULES, TERABASE_MODULES, ThrottleClickModule],
  exports: [MainLayoutComponent],
})
export class LayoutModule {}
