import { APP_INITIALIZER, LOCALE_ID, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorStateMatcher, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';

import { MatPaginatorDefaultOptions, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { ALLOWED_IMAGES_FORMATS, ALLOWED_DOCUMENTS_FORMATS } from '../injection-tokens';
import { IoTClientService } from './services/common/iot-client.service';
import { AuthenticationInterceptor } from './services/interceptors/authentication.interceptor';
import { HttpErrorInterceptor } from './services/interceptors/http-error.interceptor';
import { ManageHttpInterceptor } from './services/interceptors/managehttp.interceptor';
import { AuthenticationService } from './services/rest/authentication.service';
import { HttpCancelService } from './services/rest/http-cancel.service';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;

    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export const matPaginatorDefaultOptions: MatPaginatorDefaultOptions = { pageSizeOptions: [5, 10], pageSize: 10 };

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('Core module has already been loaded. Import Core modules in the AppModule only.');
    }
  }

  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        HttpCancelService,
        {
          provide: APP_INITIALIZER,
          useFactory: (authService: AuthenticationService) => () => authService.initialize(),
          deps: [AuthenticationService],
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (iotClientService: IoTClientService) => () => iotClientService.initialize(),
          deps: [IoTClientService],
          multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true },
        { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
        { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: matPaginatorDefaultOptions },
        { provide: ALLOWED_IMAGES_FORMATS, useValue: ['png', 'jpg', 'jpeg'] },
        {
          provide: ALLOWED_DOCUMENTS_FORMATS,
          useValue: ['pdf', 'doc', 'docx', 'txt', 'csv', 'xls', 'xlsx', 'xlsm', 'json'],
        },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        { provide: LOCALE_ID, useValue: 'en-US' },
      ],
    };
  }
}
