import { Injectable } from '@angular/core';
import { signIn, signOut, fetchAuthSession } from '@aws-amplify/auth';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { IoTClientService } from '../common/iot-client.service';
import { firstValueFrom, iif, lastValueFrom, Observable, of } from 'rxjs';
import { UserRole, UserService } from './user.service';
import { CompanyService } from './company.service';
import { BusinessRolesService } from '../common/business-roles.service';
import { PermissionsService } from './permissions.service';
import { RolesService } from '../../../permission/services/roles.service';
import { LocalStorageService } from '../common/local-storage.service';
import { LocalStorageKeyEnum } from '../../enums/local-storage-key.enum';
import { UserInterface } from '../../interfaces/user.interface';
import { FirstLoginGuard } from '../guards/first-login.guard';
import { environment } from 'src/environments/environment';
import { CookieStorage, sessionStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

export type LoginParams = {
  email: string;
  password: string;
  keepLoggedIn: string;
};

export type GeoServerAuthCredentials = {
  username: string;
  password: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public geoServerAuthHeader?: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private iotClientService: IoTClientService,
    private userService: UserService,
    private companyService: CompanyService,
    private businessRoleService: BusinessRolesService,
    private permissionsService: PermissionsService,
    private rolesService: RolesService,
    private localStorage: LocalStorageService,
    private firstLoginGuard: FirstLoginGuard,
  ) {}

  public async initialize() {
    try {
      this.configAuthStorage();
      await this.refreshCurrentUser();
    } catch (error) {
      console.error('Authentication failed: ', error);
    }
  }

  public async logIn(options: LoginParams): Promise<void> {
    try {
      this.cleanup();
      await signOut();
      if (this.localStorage.getItem(LocalStorageKeyEnum.KeepLoggedIn) !== options.keepLoggedIn) {
        this.localStorage.setItem(LocalStorageKeyEnum.KeepLoggedIn, options.keepLoggedIn);
        this.configAuthStorage();
      }

      await signIn({ username: options.email, password: options.password });
      await this.refreshCurrentUser();

      if (this.iotClientService.isEnabled) {
        const { identityId } = await fetchAuthSession();
        if (identityId) {
          await this.iotClientService.attachIoTPolicy(identityId);
        }
      }

      console.debug('Session created');
    } catch (err) {
      console.error('Log-in failed:', err);
      throw err;
    }
  }

  public async logOut(): Promise<boolean> {
    try {
      this.cleanup();
      await signOut();

      return this.router.navigate(['/user/login']);
    } catch (err: any) {
      console.error('Log-out failed:', err);
      throw new Error(err);
    }
  }

  public async getJwtAccessToken() {
    try {
      const { tokens } = await fetchAuthSession();

      return tokens?.accessToken;
    } catch (err) {
      console.error('Token retrieval failed:', err);

      return null;
    }
  }

  //TODO move to User service (and change API to get email in response not the tokens!)
  public register(
    firstName: string,
    lastName: string,
    title: string,
    invitationCode: string,
    password: string | null,
  ): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/${environment.apiVersion}/sign_up/`, {
        first_name: firstName,
        last_name: lastName,
        position: title,
        password,
        invitation_code: invitationCode,
      })
      .pipe(
        map((data: any) => {
          return { ...data.data, password: password };
        }),
      );
  }

  public requestPasswordReset(email: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${environment.apiVersion}/initiate_password_reset/`, {
      email,
    });
  }

  public resetPassword(password: string, passCode: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${environment.apiVersion}/reset_password/${passCode}/`, {
      password,
    });
  }

  public getPasswordResetEmail(passCode: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/${environment.apiVersion}/reset_password/${passCode}/email/`);
  }

  public async refreshCurrentUser() {
    try {
      const jwt = await this.getJwtAccessToken();
      if (!jwt) {
        throw new Error('No current session');
      }
      this.geoServerAuthHeader = await this.fetchGeoServerAuth();
      const currentUser = await firstValueFrom(this.getCurrentUser(jwt.payload?.['cognito:groups']));
      this.firstLoginGuard.setFirstLoginFinished(currentUser.data.first_login_finished);
      this.userService.user = currentUser.data;
      this.permissionsService.setRolesAndPermissions(currentUser.data);
      this.userService.setCurrentUser(currentUser);
    } catch (error) {
      console.error('Error while refreshing current user: ', error);
    }
  }

  private async configAuthStorage() {
    const storage =
      this.localStorage.getItem(LocalStorageKeyEnum.KeepLoggedIn) !== 'true'
        ? sessionStorage
        : new CookieStorage(environment.awsConfig.auth.cookieStorage);

    cognitoUserPoolsTokenProvider.setKeyValueStorage(storage);
  }

  private fetchGeoServerAuth(): Promise<string> {
    return lastValueFrom(
      this.http
        .get<{
          data: GeoServerAuthCredentials;
        }>(`${environment.apiUrl}/${environment.apiVersion}/geo_service/geo_server/auth/`)
        .pipe(
          map(
            (response) =>
              `Basic ${window.btoa(window.atob(response.data.username) + ':' + window.atob(response.data.password))}`,
          ),
        ),
    );
  }

  private cleanup() {
    this.geoServerAuthHeader = undefined;
    this.rolesService.flushRolesAndPermissions();
  }

  private getCurrentUser(cognitoSignInDetails: any): Observable<{ data: UserInterface }> {
    return this.userService.fetchCurrentUser().pipe(
      map((user) => {
        const userRole = this.getUserRole(cognitoSignInDetails);
        if (userRole) {
          user.data.userRole = userRole;
        }

        return user;
      }),
      switchMap((user) => {
        return iif(
          () => !!user.data.contact_profile.company,
          this.companyService.getSingleCompany(user.data.contact_profile.company).pipe(
            map((company) => {
              user.data.company_profile = company.data;
              user.data.businessRole = this.businessRoleService.getUserContextRole({
                ...user.data,
                userRole: user.data.userRole,
              });

              return user;
            }),
          ),
          of(user),
        );
      }),
    );
  }

  private getUserRole(cognitoGroups: any[]): UserRole | undefined {
    const cognitoRolesMap = cognitoGroups.reduce(
      (map: any, role: any) => map.set(role, true),
      new Map<string, boolean>(),
    );

    const isSuperAdmin = cognitoRolesMap.has(UserRole.SuperAdmin);
    const isCompanyUser = cognitoRolesMap.has(UserRole.CompanyUser);
    const isCompanyAdmin = isCompanyUser && cognitoRolesMap.has(UserRole.CompanyAdmin);

    if (isSuperAdmin) {
      return UserRole.SuperAdmin;
    }
    if (isCompanyAdmin) {
      return UserRole.CompanyAdmin;
    }
    if (isCompanyUser) {
      return UserRole.CompanyUser;
    }

    return undefined;
  }
}
