import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImagesService } from '@core/services/common/images.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'core-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit, OnDestroy {
  @Input() public size = 'auto auto';
  @Input() public custom?: any;
  @Input() public dimensions?: any;
  @Input() public newImage?: string;
  @Input() public mimeType?: string;
  @Input() public borderless = false;
  @Input() public imageIsBackground = true;
  @Input() public backgroundColor = '#F9F9F9';
  @Input()
  public get imageId(): string {
    return this._imageId;
  }
  public set imageId(value: string) {
    if (value !== this.imageId) {
      this._imageId = value;
      this.getImage(value);
    }
  }

  public image?: string;
  private _imageId: string;
  private subs = new SubSink();

  constructor(private imageService: ImagesService) {}

  public ngOnInit(): void {
    this.size = this.image ? 'auto auto' : this.size;
    this.getImage(this.imageId);
  }
  public getImage(id: string) {
    if (id !== null && id) {
      this.subs.sink = this.imageService.getPresignedUrlImage(id).subscribe((res) => (this.newImage = res.url));
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
