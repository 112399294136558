import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../rest/authentication.service';
import { environment } from '../../../../environments/environment';
import { from, switchMap } from 'rxjs';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    return from(this.authService.getJwtAccessToken()).pipe(
      switchMap((token) => {
        const requestClone = request.clone({
          setHeaders:
            token && request.url.indexOf(`${environment.apiUrl}/${environment.apiVersion}`) !== -1
              ? { Authorization: `Bearer ${token}` }
              : {},
        });

        return next.handle(requestClone);
      }),
    );
  }
}
