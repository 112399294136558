import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { RolesService } from '../services/roles.service';

@Pipe({
    name: 'can',
    standalone: false
})
export class CanPipe implements PipeTransform {
  constructor(private rolesService: RolesService) {}

  public transform(permissions: string[]): Observable<boolean> {
    return this.rolesService.hasPermissions(permissions);
  }
}
