<mat-sidenav-container class="sidenav-container" hasBackdrop="false" style="position: fixed; z-index: 1000">
  <mat-sidenav
    mode="over"
    [(opened)]="opened"
    [disableClose]="true"
    class="sidenav-content"
    [class.opened-sidenav]="openedSidenav"
    (transitionend)="openedSidenav = !openedSidenav"
    (transitioncancel)="openedSidenav ? (openedSidenav = true) : (openedSidenav = false)"
    (transitionstart)="openedSidenav = false"
    (transitionrun)="openedSidenav = false"
  >
    <ng-content></ng-content>
  </mat-sidenav>
</mat-sidenav-container>
