export enum DateTimeFormatEnum {
  StandardDateFormat = 'YYYY-MM-DD',
  StandardDateTimeFormat = 'YYYY-MM-DD HH:mm:ss',
  ShortDate = 'MMM D, YYYY',
  DayInMonth = 'MMMM DD',
  DateWithTime = 'MMM D, y | h:mm a',
  EUDateFormat = 'DD-MM-YYYY',
  FullDateWithYear = 'ddd, MMM D, YYYY',
  DateOnly = 'MMM D',
  NewGenerationDate = 'MMM D, y @ h:mm a',
}

export enum TimeStyleEnum {
  Short = 'short',
}

export enum DateStyleEnum {
  Short = 'short',
  Medium = 'medium',
  MediumDate = 'mediumDate',
  LongDate = 'long',
}

export enum TimeFormat {
  StartTime = 'T00:00:00',
  EndTime = 'T23:59:59',
}

export enum ErrorCodesFromServer {
  NoGroupsInToken = 'no_groups_in_token',
  InvalidTokenGroups = 'invalid_token_groups',
  CompanyIsDisabled = 'company_is_disabled',
  AccessDenied = 'access_denied',
}

export enum ErrorMessagesFromServer {
  InvalidTokenGroups = 'Authentication error',
  CompanyIsDisabled = "This User's company is disabled. You cannot log in.",
}

export enum ErrorExceptionsFromCognito {
  UserDisabled = 'NotAuthorizedException: User is disabled.',
  UserNotFound = 'UserNotFoundException: User does not exist.',
  InvalidCredentials = 'NotAuthorizedException: Incorrect username or password.',
}

export enum ErrorMessagesFromCognito {
  UserDisabled = 'Your account is disabled by Terabase admin. Please contact construct@terabase.energy for additional details.',
  UserNotFound = 'This user does not exist. Please contact construct@terabase.energy for additional details.',
  InvalidCredentials = 'Incorrect username or password. Please try again.',
}
