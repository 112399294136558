import { Injectable } from '@angular/core';
import { RolesService, RoleWithPermissions } from '../../../permission/services/roles.service';
import { UserInterface } from '../../interfaces/user.interface';
import { PermissionCollection } from '../../permissions/permission.collection';
import { UserRole } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private rolesService: RolesService) {}

  public setRolesAndPermissions(user: UserInterface): void {
    const rolesWithPermissions: RoleWithPermissions[] = [];

    const userRolePermissions = this.getUserRolePermissions(user);
    if (userRolePermissions) {
      rolesWithPermissions.push(userRolePermissions);
    }

    this.rolesService.setRolesWithPermissions(rolesWithPermissions);
  }

  private getUserRolePermissions(user: UserInterface): RoleWithPermissions | undefined {
    return this.userRolePermissions.find((roleWithPermissions) => roleWithPermissions.name === user.userRole);
  }

  private get userRolePermissions(): RoleWithPermissions[] {
    return [
      {
        name: UserRole.SuperAdmin,
        permissions: [
          PermissionCollection.saveProjectConfiguration,
          PermissionCollection.addTeamMember,
          PermissionCollection.editTeamMember,
          PermissionCollection.changeProjectEpc,
          PermissionCollection.logisticsInputConfiguration,
          PermissionCollection.canSeeRecenter,
          PermissionCollection.shipmentListView,
          PermissionCollection.logisticsDashboard,
          PermissionCollection.shipmentListViewFlagAction,
          PermissionCollection.deliveryVsEpcReport,
          PermissionCollection.deliveryVsMsaReport,
          PermissionCollection.deliveryVsMsaAndEpcReport,
          PermissionCollection.progressTrackingReport,
          PermissionCollection.inventoryTrackingReport,
          PermissionCollection.navigateToProjectSetup,
          PermissionCollection.editDeleteDronOrtho,
          PermissionCollection.editDeleteDronePano,
          PermissionCollection.canSeeADReviewMap,
          PermissionCollection.canSeeApprovalsMap,
          PermissionCollection.canSeeOverviewMap,
          PermissionCollection.canSeeQCIssuesMap,
          PermissionCollection.canSeeTerafabMap,
          PermissionCollection.canEditProject,
          PermissionCollection.canEditGeneral,
          PermissionCollection.canEditFeatures,
          PermissionCollection.canEditIntegrations,
          PermissionCollection.canEditMilestones,
          PermissionCollection.canEditReferenceLayers,
          PermissionCollection.canEditEquipmentSpecs,
          PermissionCollection.canAddEditWorkPackages,
          PermissionCollection.canAccessOrthoImagery,
          PermissionCollection.canEditMvCircuitStatus,
          PermissionCollection.canAddEditRovers,
          PermissionCollection.canEditWbsElements,
          PermissionCollection.canEditSchedule,
          PermissionCollection.canSeeSchedule,
          PermissionCollection.canAddNewConstructionProject,
          PermissionCollection.canAccessEditProject,
          PermissionCollection.canOpenConstructionProject,
          PermissionCollection.canEditKPIs,
          PermissionCollection.canVerifyCircuitStatus,
          PermissionCollection.canSeeOmega,
          PermissionCollection.canSeeDigitalTwin,
        ],
      },
      {
        name: UserRole.CompanyAdmin,
        permissions: [
          PermissionCollection.saveProjectConfiguration,
          PermissionCollection.addTeamMember,
          PermissionCollection.editTeamMember,
          PermissionCollection.changeProjectEpc,
          PermissionCollection.editDeleteDronePano,
          PermissionCollection.canSeeSchedule,
        ],
      },
      { name: UserRole.CompanyUser, permissions: [PermissionCollection.canSeeSchedule] },
    ];
  }
}
