import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../rest/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private router: Router, private authService: AuthenticationService) {}

  public async canActivate() {
    if (await this.authService.getJwtAccessToken()) {
      return true;
    } else {
      return this.router.createUrlTree(['user', 'login']);
    }
  }
}
